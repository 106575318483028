import local from "@/utils/local.js";
import request from "@/utils/request.js";
export default{
	goback(){
		this.$router.go(-1)
	},
	quguan(id){
		let options={
			To_Account:[id+''],
			From_Account:local.get('user_id')+'',
			DeleteType:"Delete_Type_Both"
		};
		request.txim({api:'v4/sns/friend_delete',params:JSON.stringify(options)});
	},
	guanzhu(id){
		let options={
			AddFriendItem:[{
				To_Account:id+'',
				AddSource:'AddSource_Type_yours'
			}],
			From_Account:local.get('user_id')+'',
			AddType:'Add_Type_Both',
			ForceAddFlags:1
		};
		request.txim({api:'v4/sns/friend_add',params:JSON.stringify(options)});
	},
	// 返回格式为xx天xx小时xx分钟
	getTimeOver:function(startTime, endTime,type) {
	      var runTime = parseInt(endTime - startTime)
			return(runTime);
	      	// var year = Math.floor(runTime / 86400 / 365)
	      	// runTime = runTime % (86400 * 365)
	      	// var month = Math.floor(runTime / 86400 / 30)
	      	// runTime = runTime % (86400 * 30)
	      	// var day = Math.floor(runTime / 86400)
	      	// runTime = runTime % 86400
	      	// var hour = Math.floor(runTime / 3600)
	      	// runTime = runTime % 3600
	      	// var minute = Math.floor(runTime / 60)
	      	// runTime = runTime % 60
	      	// var second = runTime
	      	// if (type === 1) {
	      	// 	return year + '年'
	      	// } else if (type === 2) {
	      	// 	return year + '年' + month + '月'
	      	// } else if (type === 3) {
	      	// 	return year + '年' + month + '月' + day + '日'
	      	// } else { 
	      	// 	return year + '年' + month + '月' + day + '日' + hour + '时' + minute + '分' + second + '秒'
	      	// }

	 },

	getTimestamp:function(date){
		
		if(date){
			
			return(Date.parse(new Date(date))/1000)
			
		}else{
			
			return(Date.parse(new Date())/1000)
		}
	},
	timestampToTime:function(timestamp,full){
		
		if(timestamp.toString().length==13){
			var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
		}else{
			var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
		}
		
		var Y = date.getFullYear() + '-';
		var M = this.fixNum(date.getMonth()+1,2)+ '-';
		var D = this.fixNum(date.getDate(),2);
		var h = ' '+this.fixNum(date.getHours(),2) + ':';
		var m = this.fixNum(date.getMinutes(),2) + ':';
		var s = this.fixNum(date.getSeconds(),2);		
		if(full){
			return Y+M+D+h+m+s;
		}else{
			return Y+M+D;
		}
		
	},
	share(href,type,title){
		console.log('分享地址',href)
		var toOpen=function(url){
			var option='toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=800, height=600,top=100,left=350';
			// option=isDevice()=='Web'?option:'';
			window.open(
				url,'_blank',option,
			);
		};
		
		switch(type){
			case 'facebook'://分享到Facebook的代码
				toOpen('http://www.facebook.com/sharer.php?u='+href+'&t='+title);
				break;
			case 'google'://分享到Google的代码
				toOpen('http://www.google.com/bookmarks/mark?op=add&bkmk='+href+'&title='+title);
				break;
			case 'twitter'://分享到twitter的代码
				toOpen('http://twitter.com/home?status='+href+' '+title);
				break;
			case 'yahoo'://分享到yahoo
				toOpen('http://myweb2.search.yahoo.com/myresults/bookmarklet?u='+href+'&t='+title);
				break;
			case 'Linkedin'://分享到Linkedin
			
				// toOpen('http://www.linkedin.com/shareArticle?mini=true&url='+href+'&title='+title);
				toOpen('https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent(href)+'&title='+title);
				
				break;
				
				
				
		}
		
	},
	getAge(date){
	  let birthDay = new Date(date)
	  let nowDate = new Date()
	  let date1 = Date.parse(birthDay)
	  let date2 = Date.parse(nowDate)
	  let day = Math.ceil((date2 - date1) / (60 * 60 * 1000 * 24))
	  let age = ''
	  let year = Math.floor(day / 365)
	  let y = day % 365
	  let month = Math.floor(y / 30)
	  let d = Math.floor(day % 365 % 30)
	  age += year + '岁' + month + '月' +  d + '天'
	  return year
	},
	fixNum:function(x){
		if(x>9){
			return (x)
		}else{
			return ('0'+x)
		}
	},
	// getTimestamp:function(){
	// 	return(Date.parse(new Date())/1000)
	// },
	timestampToTime:function(timestamp,full=1){
		
		if(timestamp.toString().length==13){
			var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
		}else{
			var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
		}
		
		var Y = date.getFullYear() + '-';
		var M = this.fixNum(date.getMonth()+1,2)+ '-';
		var D = this.fixNum(date.getDate(),2);
		var h = ' '+this.fixNum(date.getHours(),2) + ':';
		var m = this.fixNum(date.getMinutes(),2) + ':';
		var s = this.fixNum(date.getSeconds(),2);		
		if(full){
			return Y+M+D+h+m+s;
		}else{
			return Y+M+D;
		}
		
	},
	rTime:function(date) {
		return(date);
		
		// var json_date = new Date(date).toJSON();
		// return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(
		// 	/\.[\d]{3}Z/, '');
	},
	getUnitVal:function(arr,code){
		for (var i = 0; i < arr.length; i++) {			
			if(arr[i].data_code==code){
				return(arr[i].data_value)
			}
		}
	},
	downFileUrl:function(file,name) {
		  var img = file;  //注意需要拼接域名访问哦~
		  const url = img;
		  // let filename=name||file.substring(0,file.lastIndexOf('.'));
		  let filename=name||file;
		  let xmlhttp = new XMLHttpRequest();
		  xmlhttp.open("GET", url, true);
		  xmlhttp.responseType = "blob";
		  xmlhttp.onload = function () {
			if (this.status == 200) {
			  const blob = this.response;
			  const link = document.createElement("a");
			  link.href = window.URL.createObjectURL(blob);
			  // link.download = filename + url.substring(url.lastIndexOf("."), url.length);
			  link.download = filename;
			  //此写法兼容可火狐浏览器
			  document.body.appendChild(link);
			  const evt = document.createEvent("MouseEvents");
			  evt.initEvent("click", false, false);
			  link.dispatchEvent(evt);
			  window.URL.revokeObjectURL(link.href);
			  document.body.removeChild(link);
			}
		  };
		  xmlhttp.send();
	},
}