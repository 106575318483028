module.exports =  {
	SCZPJ:"ポートフォリオアップロード",
	DFJFL:'新納品申請あり、ご確認ください',
	YXTS:'操作なしで提出する場合、１★と見なされます。',
	PINGLUN:'レコメンドする',
	CONFIRM2:'はい',
	CANCEL2:'いいえ',
	RIBEN:'Japan',
	OTHERLINK:'Other',
	SKILL:'Skill',
	LBIMGTIP:"「※イベント一覧部分に表示される画像」と記載",
	ZXIMGTIP:"「※イベント詳細画面内で表示される画像」と記載",
	TZXX:"Notification message",
	QBJHWC:"※却下されたので、もう一回納品してください",
	QBJHWC2:"※納品された方は納品完了をクリックしてください",
	YBJ:"Rejected",
	QRQXM:'Are you sure to cancel',
	HDYJS:'The activity has ended',
	BMRSYM:'The number of applicants is full',
	QDBMM:"Are you sure you want to register",
	YSQ:"Applied for",
	JUBAO:"Report",
	QSRJBLY:"Please enter the reason for reporting",
	FAV: "Favorites",
	QXZDQ: "Please select region",
	ABOUTUS:[
		"昨今の働き方改革やジョブ型雇用への移行トレンドの中で、",
		"人々は、より自由で自分らしい働き方・生き方を志向するようになりました。",
		"一方、フリーランスや副業ワーカーは未だ“マイノリティ”として位置付けられ、",
		"一部では社会的信用度に乏しく、マイナスイメージを持たれていることも事実です。",
		"自由な働き方を選びたい。自分の実力を試したい。",
		"誰かの役に立ちたい。仲間と出会い、ともに成長していきたい…。",
		"我々は、そんなフリーランス・副業ワーカーのインフラとなり、",
		"市場活性化及び信用度向上の一助となることで、人々の挑戦を応援したいと考えています。"
	],
	
	TGS: "Submissions",
	FANS: "Fans",
	QXZ: "Choose",
	QSR: "Enter",
	QSC: "Upload",
	FOLLOW: "Follow",
	XUELI: "Study",
	HOPETIME: "Work time",
	MEITIAN:'(1日あたり)',
	EVERY: "Every",
	HOPSTART: "Start time",
	JIANZHONG: "items",
	JIAN:"items",
	REALORDER: "Real order",
	REALORDER: "Real order",
	ALLAMOUNT: "Accumulated",
	AGEOPEN: "Age disclosure",
	EMAILPHONE: "Email/Mobile",
	SEXOPEN: "Gender disclosure",
	NAME: "Name",
	VAL: "Content",
	KSRQ: "Start",
	JSRQ: "End",
	TJXMJL: "Add experience",
	QTXWZXX: "Please fill in the complete information",
	MIN: "Above",
	MAX: "below",
	USEKEYWORD: "Search",
	USEKEYWORD2:"名前で検索",
	RMPX: "Popular",
	ZXFB: "Latest",
	JZBMSJ: "Deadline",
	BYJN: "Skills",
	TIME: "Time",
	YJ: "Commission",
	REN: "Person",
	FEN: "min",
	FBSJ: "Release time",
	RWJJ: "Introduction",
	TIAN: "day",
	HH: "hour",
	MM: "point",
	SS: "seconds",
	HJZ: "Later Cutoff",
	GZDZ: "work address",
	JOBTIME:{0:'day',1:'week',2:'month'},
	GZGX: "Relationship",
	JBLC: "Receive process",
	SQWCGZ: "Apply complete",
	WCJSGZ: "Complete",
	GXS: "hour",
	YXZK: "Health Status",
	SJDJ: "Unit price",
	FSXX: "Send message",
	FBZ: "Publisher",
	QBGZ: "All",
	QXGZ: "Unfollow",
	ZHIYE: "Occupation",
	FB: "Publish",
	FS: "Send",
	CKGD: "See more",
	YJDDL: "It's over",
	XXXQ: "School Details",
	SSZY: "Professional",
	YCJ: "Participated",
	BM: "Registration",
	QXBM: "Cancel",
	START: "Start",
	END: "End",
	SHZT: "Audit Status",
	SSHDMC: "Search Event Name",
	HDSQ: "Time Zone",
	HDKSSJ: "Event start time",
	HDJSSJ: "Event End Time",
	XSFS: "Online",
	HYID: "Alis Meeting ID",
	HYPWD: "Alis Meeting Pwd",
	TOALIS: "Generate",
	HDLJ: "Event Link",
	ADR: "Address",
	CONFIRM: "OK",
	CANCEL: "Cancel",
	QRWC: "Completed",
	SFWTMC: "Three-party entrusted name",
	SXFW: "Hourly Wage Range",
	HIGH: "High",
	LOW: "Low",
	QDWCGZ: "Confirm completed",
	QSRBHYY: "Please enter the reason for rejection",
	TISHI: "Prompt",
	QRGYWCGZ: "Please make sure has completed all work",
	QHDNBGZGL: "Switch to internal job management",
	QI: "Enterprise",
	ZJ: "Intermediary",
	GZWC: "Job completed",
	JJSJ: "Deny time",
	RC: "Schedule",
	BD: "Expression",
	HHQY: "Partnership",
	QRWCWORK: "Please make sure you have completed all work-related work",
	FSMMCZYZM: "Send password reset verification code",
	SRFOURYZM: "Please enter a 4-digit verification code",
	PWDRULE: '6 to 16 single-byte alphanumeric and symbols',
	QDMM: "Confirm Password",
	RJYZM: "Captcha",
	IAGREE: "I agree",
	ADRTIP: "A real address is required when subscribing/publishing work",
	NAMETIP: "Real name is required when subscribing/publishing work",
	HYZ: "Change one",
	AND: "and",
	GRXXBHZC: "Protection Policy",
	YDTYZC: "Please read and agree <Terms of Use> and <Protection Policy>",
	ACCOUNTTIP: "Enter your registered email or phone number",
	PWDTIP: "Enter login password",
	QSRACCOUNT: "Enter your email or phone number to register",
	GXXR: "Thank you for trust in YOURS",
	GZBH: "Job Number",
	NOMORE: "No More",
	QDSCM: "Are you sure to delete?",
	TJXXXQ: "Recommendation",
	GRYH: "Individual user",
	QYYH: "Enterprise user",
	index:{
		title1:'Work, partner, network. ',
		title2: 'Everything you need for a freelancer',
		slon1: 'YOURS is a new form of SNS platform that was born to make freelancers shine more. ',
		p1tit: 'Freelancer・Find a side job case',
		p2tit: 'Visualization of freelancers performance and credit',
		p3tit: 'Connect with friends through SNS',
		p1txt: 'Handle freelancer and sideline cases of X type of job, not only corporate cases, but also all cases of cooperation agency X company. In addition, the case flow from recruitment/scout to collection can be managed in a unified manner. ',
		p2txt:'Introductions of freelancers experience, case results, third-party evaluations, productions, etc. are all concentrated on the YOURS dashboard. Make career accumulation as a freelancer possible, and realize the visualization of social credit. ',
		p3txt:'YOURS provides a SNS platform specialized for freelancers and sideline workers beyond the field of job matching services. Connecting freelancers allows for open information exchange and community formation. ',
		index_one: "2",
		title3: "Different from existing job matching sites",
		index_one_img: "2",
		ljzchy: "Sign up as a member now",
		qyzb: "Enterprise side",
		title4: "Freelancers, businesses and company employees",
		title5: "In the future, please adopt a more free way of working.",
		slon2: "YOURS seek higher quality work",
		slon3: "It is a service developed for everyone.",
		slon4: "Everyone below, please do your research.",
		ystit1: "Freelancers and sideline workers",
		ystit2: "Company employees",
		ystit3: "Everyone in the enterprise",
		ystit4: "Entrepreneurs and operators",

		ystxt1: "I want to establish a connection between freelancers and sideline workers",
		ystxt2: "I want to expand the scope of work and accumulate experience",
		ystxt3: "I want the certificate of the actual case",
		ystxt4: "I want to find someone to do part of the work",

		ystxt5: "I want to easily know what sideline cases are available",
		ystxt6: "I hope there is an information exchange place about sideline business",
		ystxt7: "Interested in a life free from corporate constraints",
		ystxt8: "I want to prepare funds for starting a business",

		ystxt9: "I want to flexibly secure highly specialized talents",
		ystxt10: "If you go through a consulting company, the sense of cost is not appropriate",
		ystxt11: "Purchase outstanding talents by name",
		ystxt12: "I want to prevent the mismatch of recruitment",

		ystxt13: "I want to do my favorite job while doing my job",
		ystxt14: "Want to expand contacts and network",
		ystxt15: "I want to entrust freelancers and sideline workers to work",
		ystxt16: "I want to promote my company's business",
		zpxx: "Recruitment information",
		weizhi: "Location",
		xinzi: "Salary",
		title6: "I have a job that suits you very well",
		slon5: "Cloud workers of various occupations are being registered.",
		slon6: "to match the scale of the job, the sense of speed, the budget",
		slon7: "You can find excellent talents and form a team.",

		zixun: "Consultation",
		zixun1: "Consulting Strategy Consulting",
		zixun2: "Finance and Accounting Consulting",
		zixun3: "Personnel and Organization Consulting",
		zixun4: "Business Process Consulting",
		zixun5: "Logistics, SCM consulting",
		zixun6: "Marketing Consulting",
		zixun7: "Research, research",
		zixun8: "IT Consulting",

		shangye: "Business",
		shangye1: "Business planning and business strategy filing",
		shangye2: "New business planning, business development",
		shangye3: "Mergers and acquisitions, cooperation",
		shangye4: "Finance, Accounting, Accounting",
		shangye5: "Internal Audit and Internal Control",
		shangye6: "sales",

		renshi: "Personnel",
		renshi1: "Employment",
		renshi2: "Organization development and system design",
		renshi3: "Labor service, wages",

		it:'IT·Development',
		it1:'Web application development',
		it2:'Mobile application development',
		it3:'infrastructure design and construction',
		it4:'Security measures',
		it5:'System operation and maintenance',
		it6:'ERP import, CRM import',
		it7:'Data analysis and data utilization',
		it8:'Network Director',
		it9: 'PMO',

		sheji:'design',
		sheji1:'graphic design',
		sheji2:'WebUI design',
		sheji3:'WebUX design',
		sheji4:'Product Design',
		sheji5:'package design',
		sheji6:'art direction',
		sheji7:'Creative guidance',
		sheji8: 'Space・Store Design',
		sheji9:'interior design',

		scyx: "Marketing",
		scyx1: "Blanking",
		scyx2: "Advertising",
		scyx3: "Product Planning and Development",
		scyx4: "Internet advertising operation",
		scyx5: "Digital Marketing",

		cyzz: "creative production",
		cyzz1: "Animation, video production, editing",
		cyzz2: "photography, editing",
		cyzz3: "Create home page",
		cyzz4: "Lighting",
		cyzz5: "copy lighting",
		cyzz6: "illustration development",
		cyzz7: "Music production",

		qita: "other",
		qita1: "Translation, Interpretation",
		qita2: "photographic model",
		qita3: "data input",
		qita4: "Various agents",

		title7: "Visualize skills, experience, and advantages",
		title8: "YOURS Dashboard",
		slon8: "Lead by workplace, time, desired price band",
		slon9: "Visualize skills and performance etc. on a dashboard.",
		slon10: "Achieving the best match for the job.",

		title9: "Send yourself and connect with the world.",
		slon10: "Needless to say, job recruitment and application are just like SNS",
		slon11: "Express your individuality freely",
		slon12: "We can communicate.",

		title10: "The way you work is up to you.",
		title11: "YOURS shines through work",
		title12: "Meet with friends",
		title13: "For those who want to enjoy life",
		title14: "It is a new 'habitat'. ",

		slon13: "With a free lifestyle and work style as my ambition, I take control of the steering wheel of my life"
		
	},
	header:{
		ZGZ: "Job",
		XZRC: "Talent",
		LJ: "Feeds",
		FBGZ: "Post Job",
		FBHD: "Post Event",
		HD: "Event",
		QYDL: "Company Login",
		GRDL: "Personal Login",
		QYZC: "Company Regist",
		GRZC: "Personal Regist",
		WDZY: "My Page",
		HDZX: "Event Centre",
		RWGL: "Tasks",
		GZZX: "Work Centre",
		WDGZ: "My Followers",
		XGMM: "Change Password",
		QHBJ: "Switch Color",
		TCDL: "Logout",

		YSZC: "Privacy Policy",
		SYTK: "Terms of Use",
		CLGRXX: "Personal info",
		DL: "Login",
		TDJYFBS:"Specific transaction law expression",
	},
	// 编辑企业
	QY_msg:{
		JBXX: "Basic Info",
		FGK: "Non-public",
		QYMC: "Company Name",
		GK: "Public",
		SSHY: "Industry",
		QXZ: "Select",
		SSXX: "Listed Info",
		SSQY: "Listed Company",
		FSSQY: "Unlisted Company",
		QYGM: "Company Size",
		XSGM: "Sales Size",
		YGGM: "Employees",
		ZFFS: "Payment Method",
		PJXS: "Rating",
		QYMC: "Company Name",
		SSZT: "Listing",
		WBLJ: "External Link",
		QYGW: "Company website",
		LXDH: "Contact Phone",
		YZBM: "Post Code",
		DDFX: "Prefectural",
		DWDZ: "Location",
		XQDZ: "Address",
		QYLOGO: "Company Logo",
		TPSC: "Image Upload",
		QYJJ: "Company Profile",
		ZZXX: "Qualification",
		YYZC: "License",
		YYZCMSG:"提出すると提出済みマークが表示されます",
		QTZJ: "Other",
		DJSC: "Upload",
		BCXX: "Save",
	},
	// 编辑
	my_msg:{
		QZXX: "Job Info",
		RZSJ: "Entry time",
		KSSJ: "Start time",
		XWXZ: "Hourly rate",
		CQSJ: "Attendance time",
		ATJS: "Time by day",
		ZZJ: "Per week",
		MZ: "Weekly",
		XS: "Hours",
		AYJS: "By month",
		XWGW: "Position",
		XZNL: "Ability",
		TDTZSX: "Drag to adjust",
		BGFS: "Office style",
		KCC:'Can trave',
		KYC:'Can remote',
		KKQ:'Attendance',
		KWP:'Assignment',
		XLDJ:'Education',
		SKRXM:'Payee\'s name',
		QSRSKRXM:'Please enter payee\'s name',
		SKRDZ:'Payee\'s address',
		QSRSKRDZ:'Please enter the payee\'s address',
		SKZH:'Account number of payee',
		QSRSKZH:'Please enter the payee account number',
		SKYH:'Beneficiary Bank',
		QSRSKYH:'Please enter the payee bank',
		SKHB:'Receive Currency',
		QSRHKHBDW:'Please enter the currency unit of receipt',
		QSRSwiftcode:'Please enter Swiftcode',
		ZDMC:'Field Name',
		ZDNR:'Field content',
		TJZDYZD:'Add custom field',
		XXJL:'Learning Experience',
		TJXXJL:'Add learning experience',
		XXMC:'School name',
		SXZY:'Major studied',
		QSRXXMC:'Please enter the name of your study',
		QSRZYMC:'Please enter the name of your major',
		ZXSJ:'Duration of study',
		KSSJ:'Start time',
		JSSJ:'Ending time',
		ZWXC:'Position salary',
		CZ:'Junior High School',
		ZXJL:'School experience',
		QSRNSFZDNR:'Please enter the section you are responsible for',
		GZJL:'Work experience',
		TJGZJL:'Add work experience',
		QSRGSMC:'Please enter the name of the company',
		SSZW:'Position',
		QSRXMGSGSMC:'Please enter the name of the company to which the project belongs',
		ZZSJ:'Duration of employment',
		GZNR:'Job Description',
		GZCJ:'Work performance',
		QSRNSFZDBFDCG:'Please enter the results of the section you are responsible for',
		XMJL:'Project experience',
		XMMC:'Project name',
		QSRXMMC:'Please enter the name of the project',
		XMSS:'Project Affiliation',
		XMSJ:'Project Time',
		KSSJ:'Start time',
		FZSX:'Responsible for',
		XMCG:'Project outcome',
		BCXX:'Save information',
		JBXX:'Basic information',
		ZCXX:'Registration Information',
		CSRQ:'Date of birth',
		NL:'Age',
		S:'Year',
		YWNL:'Business ability',
		XZNL:'Ability to select',
		ZYBS:'Career endorsement',
		TZTZSX:'Drag to adjust order',
		LALBB:'Example: Director, Alibaba E-Commerce Division',
		XB:'Gender',
		MAN:'Male',
		WOMAN:'Female',
		BXHD:'Don\'t want to answer',
		XBGKXZ:'Gender is publicly selected',
		NC:'Nickname',
		GK:'Public',
		FGK:'Non-public',
		ZSXM:'Real name',
		ENXM:'English name',
		GKZSXM:'Public real name',
		LXFS:'Contact details',
		YZBM:'Postal Code',
		DDFX:'prefecture',
		DWDZ:'Location address',
		XXDD:'Full address',
		BCXX:'Additional information',
		TX:'Avatar',
		ZPJ:'Portfolio',
		TPSC:'Image Upload',
		WBLJ:'External links',
		GRJJ:'Profile',
		QR:'Confirmation',
	},
	// 动态
	home:{
		DTGC:'Dynamic Square',
		GZDT:'Follow Dynamic',
		WDDT:'My News',
		TJYH:'Recommended Users',
		FDXX:'Send Message',
		FH:'Return',
		YGZ:'Followed',
		WGZ:'Unfollowed',
		PL:'Comment',
		FBNDPL:'Post your comment',
		CKQBPL:'View all comments',
		XX:'Message',
		HF:'Reply',
		SS:'Search',
	},
	// 发布活动
	add_activity:{
		HDZX:'Activity Centre',
		SSHD:'Search Activity',
		YNCJ:'Created by you',
		GXQD:'Interested in',
		CYD:'Participating',
		WQHD:'Past Events',
		FBHD:'Post Event',
		HDMC:'Event Name',
		HDSJ:'Event time',
		SQ:'Time Zone',
		Z:'To',
		HDDZ:'Event Location',
		XS:'Online',
		XX:'offline',
		DTXD:'Map Selection Point',
		HDRS:'Event Number',
		HDDD:'Restrictions',
		WXZ:'No limit',
		XSH:'Subject to review',
		LBTP:'List Image',
		TJBL:'Recommendation ratio',
		ZSTP:'Show images',
		GRJJ:'Profile',
		HDXQ:'Event Details',
		QX:'Cancellation',
		HY:'Meeting',
		QSRAlisHYID:'Please enter Alis Meeting Room ID',
		QSRAlisHYMM:'Please enter the Ais meeting password',
		QAlisSCHY:'Go to Ais to generate a meeting',
		QSRXSHDLJ:'Please enter the online event link',
		QR:'Confirm',
	},
	job:{
		ZZZX:'Work Center',
		QHDWBGZGL:'Switch to external job management',
		SSRW:'Search Task',
		DKS:'To be started',
		JXZ:'In progress',
		YWC:'Completed',
		FBGZ:'Posting job',
		ZYXZ:'Career selection',
		ZDKXZZZ:'Up to 5 professions to choose from',
		SJS:'Designer',
		ZJ:'Writer',
		JZ:'Journalist',
		WNZZ:'Web production',
		JR:'Finance',
		GZMC:'Job title',
		SXYWNL:'Required business skills',
		GZGS:'Job attribution',
		ZZWT:'Direct commission',
		SFWT:'Three way commission',
		QSRWTDSFDMC:'Please enter the name of the delegating third party',
		JCFS:'Payment method',
		AGZSC:'By hours worked',
		GDYJ:'Fixed commission',
		ACJF:'Per job',
		YJFW:'Commission Range',
		Z:'To',
		D:'Low',
		G:'High',
		GZSJ:'Working Hours',
		GZLGD:'Workload fixed',
		GZSJGD:'Working hours fixed',
		CQGZ:'Working on a permanent basis',
		CQSJ:'Attendance time',
		ATJS:'By day',
		AZJS:'Weekly',
		AYJS:'Monthly',
		GZDD:'Workplace',
		HW:'Overseas',
		GZFS:'Work Style',
		YC:'Remote',
		XX:'Offline',
		BX:'Unlimited',
		SFCC:'Does travel',
		XYCC:'Travel required',
		WXCC:'No travel required',
		XQRS:'Number of persons required',
		SFJP:'Other',
		PTZM:'General recruitment',
		JP:'Urgent recruitment',
		GZXX:'Nature of work',
		FY:'Side job',
		JZRQ:'Closing date',
		XGWJ:'Related documents',
		CYKXZ:'downloadable by participants',
		SYRKXZ:'Available to all',
		YoursYXRP:'Yours prototype RP',
		DJSC:'Click to upload',
		GZJJ:'Job Profile',
		GZSM:'Job description',
	},
	login:{
		QYZC:'Business Registration',
		GRZC:'Personal Registration',
		LJDL:'Log in now',
		BCDLZT:'Stay logged in',
		WJDLMM:'Forgot login password',
		QTDLFS:'Other login methods',
		QTDLFS2:'Log in with the following account',
		CJXZH:'Create new account',
		QSRNZCSDDZYX:'Please enter your registered email address or contact number',
		QSRNDMM:'Please enter your Yours login password',
		
	},
	work:{
		QZRQ:'Date of work',
		BHWC:'Rejected as complete',
		QDGZWC:'Determine work complete, employee personally changed to complete work, please determine within three working days if the document meets the requirements',
		WCGZ:'Work completed',
		JFWJXZ:'Delivery document download',
		HTQD:'Contract signed',
		JFGZ:'Delivery of work',
		GZJS:'Work Settlement',
		GZDP:'Job Review',
		ZKCKGD:'Expand to see more employee progress',
		JN:'Skills',
		ZL:'Quality',
		GT:'Communication',
		GX:'Relationships',
		JD:'Progress',
		SJYJ:'Actual Commission',
		QSRPJNR:'Please enter evaluation content',
		QSRSJJFYJ:'Please enter the actual commission paid for record purposes',
		JDPF:'Confirm rating',
		FS:'Send',
		GZXZ:'Job Delivery',
		LSJF:'History of delivery',
		SCDGZ:'Favorite jobs',
		JXZDGZ:'Work in progress',
		SQZDGZ:'Work in progress',
		WQGZ:'Previous Jobs',
		QD:'Confirmation',
		QQDYWC:'Please make sure all relevant work has been completed',
		SQZ:'Application in progress',
		YJJ:'Rejected',
		NDPJ:'Your comment',
		WJSJ:'Completion time',
		WBGZ:'External work',
		JHDZCJ:'Switching to self-created job management',
		QSRHZGCZ:'Please enter what you want to say during the collaboration',
		PJNYGZHZ:'Rate how you feel about working with your employer',
		QXSC:'Cancel Bookmark',
	},
	job_detail:{
		WTR:'Principal',
		FR:'legal person',
		GR:'Individual',
		TC:'Genre',
		SCYX:'Marketing',
		SJZZXG:'Design and production related',
		QT:'Other',
		ZX:'Consulting',
		RS:'Personnel',
		WTFS:'Commissioning Method',
		XM:'Project',
		GDBCZ:'Fixed remuneration system',
		KSL:'Negotiable',
		SJDJZ:'Time unit price',
		JYRW:'Task-based',
		BC:'Remuneration',
		WZD:'Not specified',
		YX:'Below',
		QTSX:'Other filters',
		KYC:'Available remotely',
		JP:'Urgent',
		FSZM:'Plural Recruitment',
		KJZ:'Part-time available',
		JCXX:'Basic Information',
		GZPF:'Employer rating',
		BMQK:'Registration status',
		BMRS:'Enrolment Status',
		BMRS:'Enrollment',
		GDYJ:'Fixed Commission',
		SCRS:'Number of Favorites',
		QDRS:'Number of confirmed',
		SQGZ:'Apply for job',
		DDXL:'Waiting for Sage',
		TXSJZ:'*day*hour deadline',
		BMJZ:'Application deadline',
		ZMYQ:'Recruitment requirements',
		GZXQ:'Job Details',
		DJXZ:'Click to download',
		XBLC:'Selection Process',
		GZXD:'Employer Selection',
		QDHT:'Sign the contract',
		KSGZ:'Start work',
		JFGZ:'Deliver the job',
		SQWC:'Application completed',
		JYWC:'Transaction completed',
		RWJD:'Task progress',
		JFSM:'Delivery Notes',
		SYYMTJWC:'All page submissions complete, please settle work as soon as possible',
		JFSJ:'Delivery time',
		WJSC:'File upload',
		JFSM:'Delivery instructions',
		ZSJGHXYM:'This is the design of a few core pages, see if you are happy with the style',
	},
	activity:{
		HDLB:'Event List',
		FXHD:'Discover Events',
		HDXS:'Event Form',
		HDZT:'Event Theme',
		QB:'All',
		PXFS:'Sort by',
		ZNPX:'Smart Recommendation',
		CJ:'Participate',
		HDXX:'Event Info',
		FQZ:'Initiator',
		FQSJ:'Initiation time',
		HDSJ:'Event Time',
		WYCJ:'I want to participate',
		JHDD:'Meeting Point',
		
		HDRS:'Number of participants',
		CJXZ:'Participation Limit',
		HDJJ:'Event Description',
		BJHD:'Edit Event',
		BJ:'Edit',
		SC:'Delete',
		CYZ:'Participant',
		GXQD:'Interested',
		DSH:'Be reviewed',
		YSH:'Reviewed',
		QQDSC:'Please confirm deletion',
		QF:'Group sending',
		QFXX:'Group message',
		QSRQFDXXNR:'Please enter the message',
		QFXXGCYZ:'Send message to participants',
		GLHD:'Manage Event',
		YJS:'Closed',
		GXQ:'Interested',
		YBM:'Enrolled',
		YCY:'Participated',
		NQDYQXBMHD:'Are you sure you want to cancel registration',
		YNCJ:'Created by you',
		WCYD:'I attended',
		YCHD:'Remove event',
		NQDYSCBMHD:'Are you sure you want to remove the event',
		NQDYYCHD:'Are you sure you want to remove the event',
		TP:'Image',
		SP:'Video',
		HD:'Event',
		CKQBPL:'View all comments',
		YSMXFXGDJD:'Have something to share',
	},
	QY:{
		YRZ:'Certified',
		HYGS:'Industry',
		RYGM:'Size',
		PFCS:'Ratings',
		GYPF:'Employee rating',
		QYGW:'Company website',
		ZT:'Status',
		QYJJ:'Company Profile',
		AJPJ:'Case Evaluation',
		QBDT:'All News',
		WZSPJ:'Undisplayed',
		YZSPJ:'Displayed',
		XPLMRSFZS:'Show new reviews',
		YC:'Hide',
		ZS:'Show',
		JP:'Urgent',
		GDXZ:'Fixed Salary',
		WCSJ:'Completion Time',
		PF:'Rating',
		FBRWS:'Posted tasks',
		PJXC:'Average salary',
		MFZC:'Free',
		YYZH:'Sign in now',
		// ZCCWHY:'You agree to the Terms of Use and Protection Policy when registed',
		ZCCWHY:'You agree to the',
		ZCCWHY2:'when registed',
		XYB:'Next',
		FH:'Back',
		CXFS:'Resend',
		FHCXFS:'Return to resend',
	},
	task:{
		GYCK:'Employee',
		JDCK:'Progress',
		GZPJ:'Job Evaluation',
		GZXX:'Job Info',
		GZMC:'Job Title',
		FQSJ:'Initiation Time',
		XQRS:'Number of people',
		GZYJ:'Commission',
		ZSYQ:'Certification',
		ZNYQ:'Skills',
		BJGZ:'Edit work',
		BJ:'Edit',
		GZJJ:'Job Profile',
		SQZ:'Applicant',
		MTZ:'Interview',
		YJJ:'Declined',
		YTG:'Passed',
		DDMS:'Awaiting interview',
		GGZZZJXZ:'The job is in progress and cannot be deleted',
		SQJSGZ:'Apply settlement of work',
		WCJSGZ:'Completion of billing job',
		SQJS:'Apply settlement',
		WCJS:'Complete Settlement',
		ZDPF:'Automatic scoring',
		TDFS:'His fans',
		TDGZ:'His followers',
		ZKCKGDRW:'Expand to see more',
		ZWGD:'No content',
		ZWGDFS:'No more fans',
		ZWGDGZ:'No more followers',
		QQDQXGZ:'Please confirm to unfollow',
		XGZDFH:'You need to follow each other before send message',
		SF:'Three parties',
		XMGL:'Project Management',
		LT:'Chat',
		JNPG:'Skills assessment',
		SJPG:'Practical Assessment',
		DDPG:'Order Assessment',
		CGDDPG:'Purchase Assessment',
		CPZH:'Portfolio',
		TJXXXX:'Recommend details',
		TJ:'Recommend',
		XQXX:'Details',
		JB:'Receive',
		FB:'Post',
		GX:'Update',
		XXXQ:'Study Details',
		XXMC:'Study Name',
		GSMC:'Company Name',
		GWMC:'Job Name',
		GWXC:'Salary',
		RZSJ:'Work time',
		GZNR:'Job content',
		ZYMC:'profession',
		XMSS:'Affiliation',
		XMSJ:'Duration',
		FZSX:'Responsible',
		DZYXDZ:'Email',
		DZJZMSZ:'8 to 16 single-byte alphanumeric and symbols',
		MM:'Password',
		QDMM:'Confirm password',
		LN:'Age',
		RJYZ:'Verification',
		QDZC:'Confirm Regist',
		WTYSYTK:'I agree to the Terms of Use and Protection Policy',
		WDGZ:'My followers',
		WDFS:'My fans',
		TJGZ:'Refer job',
		FGK:'Non-public',
		YZBM:'Postcode',
		TJHD:'Referral Event',
		DWDZ:'Location',
		CSRQ:'Birthday',
		QXZCSRQ:'Please select birthday',
		ZYBS:'Profession',
		BMCJ:'Enrolment',
		GZLM:'Type of work',
		RWL:'Task amount',
		SYXW:'Remain seats',
		JJDZ:'Address',
		HYH:'Change one',
		HG:'Follow',
		YHG:'Followed',
		QBFS:'All fans',
		SSYH:'Search',
		QDBZGZCR:'Make sure you\'re not following this person',
		JDJTGYXJXFK:'Progress will be fedback via email',
		XGYX:'Modify email',
		XG:'Modify',
		DZ:'Like',
		PL:'Comment',
		PL2:'Comment',
		PL3:'Comment',
		FX:'Share',
		HF:'Reply',
		RQ:'Date',
		PSY:'Reviewer',
		PF:'Rating',
		XMLX:'Item type',
	},
	other:{
		GX:'Update',
		KYC:'Remotely',
		FSMC:'Collection',
		KJZ:'Part-time',
		WTFS:'Commission',
		TC:'Genre',
		SCYX:'Marketing',
		SJ:'Design',
		ZZXG:'Production',
		QT:'Other',
		ZX:'Consulting',
		RS:'Personnel',
		SY:'Commercial',
		FR:'Corporate',
		ZJAJ:'Cases',
		RM:'Hot',
		ZX:'Latest',
		YJGD:'Commission',
		G:'High',
		D:'Low',
		GJZSS:'Search',
		JBMSJ:'Time until regist',
		JRWKSSJ:'Time until task start',
		BC:'Save',
		XYRS:'Number',
		CZMM:'Reset password',
		FHDL:'Login',
		FSYZM:'Send verification code to reset',
		QSRNZCDDDYJDZ:'Please enter your registered email address.',
		DZYX:'Email',
		SJHM:'Mobile number',
		FSCG:'Sent successfully',
		QDDZYJ:'Please enter the 4 digit verification code',
		QDDZYJ2:'Confirm that the SMS verification code has been sent to the registered mobile number',
		QSRGGHDMM:'Please enter the changed password',
		QCZSRMMYJXQD:'Please enter the changed password again',
		GXDLXX:'Update info',
		PFPX:'Rating',
		DLSJPX:'Login time',
		XCPX:'Hourly rate',
		ZCSJ:'Regist time',
		CJSJ:'Creation time',
		SQRS:'Number of applicants',
		CKXQ:'Detail'
		
	}
}