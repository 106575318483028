import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const routes = [


	{
		name: 'home',
		path: '/',
		component: () => import('../views/home_header.vue'),
		children:[
			{
				path: '/about',
				component: () => import('../views/about.vue'),
			},
			// 登录
			{
				path: '/login',
				component: () => import('../views/login/login.vue'),
			},
			{
				path: '/login_QY',
				component: () => import('../views/login/login_QY.vue'),
			},
			{
				path: '/register',
				component: () => import('../views/login/register.vue'),
			},
			{
				path: '/register2',
				component: () => import('../views/login/register2.vue'),
			},
			{
				path: '/GR_form',
				component: () => import('../views/login/GR_form.vue'),
			},
			{
				path: '/forget',
				component: () => import('../views/login/forget.vue'),
			},
			{
				path: '/QY_form',
				component: () => import('../views/login/QY_form.vue'),
			},
			// 
			{
				path: '/',
				component: () => import('../views/index.vue'),
			},
			{	
				name: 'home_index',
				path: '/home',
				component: () => import('../views/home/home.vue'),
			},
			
			{
				name: 'my_index',
				path: '/my',
				component: () => import('../views/my/my_index')
			},
			{
				// 个人中心
				name: 'my_msg',
				path: '/my_msg',
				component: () => import('../views/my/my_msg')
			},
			{
				// 我的关注
				name: 'my_guanz',
				path: '/my_guanz',
				component: () => import('../views/my/my_guanz')
			},
			{
				// 人才主页
				name: 'look_people',
				path: '/look_people',
				component: () => import('../views/home/look_people')
			},
			{
				// 寻找人才
				name: 'people_page',
				path: '/people_page',
				component: () => import('../views/home/people_page')
			},
			{
				// 企业主页
				name: 'qypage',
				path: '/qypage',
				component: () => import('../views/home/qypage')
			},
			{
				// 企业主页
				name: 'inform_list',
				path: '/inform_list',
				component: () => import('../views/home/inform_list')
			},
			{
				// 寻找工作
				name: 'look_job',
				path: '/look_job',
				component: () => import('../views/home/look_job')
			},
			{
				// 工作详情
				name: 'job_detail',
				path: '/job_detail',
				component: () => import('../views/home/job_detail')
			},
			{
				// 活动列表
				name: 'activity_list',
				path: '/activity_list',
				component: () => import('../views/home/activity_list')
			},
			{
				// 活动
				name: 'activity',
				path: '/activity',
				component: () => import('../views/home/activity')
			},
			{
				// 添加活动
				name: 'add_activity',
				path: '/add_activity',
				component: () => import('../views/home/add_activity')
			},
			{
				// 编辑活动
				name: 'edit_activity',
				path: '/edit_activity',
				component: () => import('../views/home/edit_activity')
			},
			{
				// 活动管理
				name: 'activity_admin',
				path: '/activity_admin',
				component: () => import('../views/home/activity_admin')
			},
			{
				// 活动详情
				name: 'activity_detail',
				path: '/activity_detail',
				component: () => import('../views/home/activity_detail')
			},
			{
				// 工作
				name: 'work',
				path: '/work',
				component: () => import('../views/home/work')
			},
			{
				// 工作
				name: 'work_edit',
				path: '/work_edit',
				component: () => import('../views/home/work_edit')
			},
			{
				// 外部工作
				name: 'work2',
				path: '/work2',
				component: () => import('../views/home/work2')
			},
			{
				// 企业
				name: 'QY',
				path: '/QY',
				component: () => import('../views/QY/QY')
			},
			{
				// 企业
				name: 'QY_guanzhu',
				path: '/QY_guanzhu',
				component: () => import('../views/QY/QY_guanzhu')
			},
			{
				// 任务管理
				name: 'task',
				path: '/task',
				component: () => import('../views/QY/task')
			},
			{
				// 企业信息
				name: 'QY_msg',
				path: '/QY_msg',
				component: () => import('../views/QY/QY_msg')
			},
			{
				// 使用条款
				name: 'tiaokuan',
				path: '/tiaokuan',
				component: () => import('../views/tiaokuan')
			},
			{
				// 隐私政策
				name: 'yinsi',
				path: '/yinsi',
				component: () => import('../views/yinsi')
			},
			{
				// 特定交易法表述
				name: 'jiaoyifa',
				path: '/jiaoyifa',
				component: () => import('../views/jiaoyifa')
			},
		]
	},
	

]

const router = new VueRouter({
	mode: "hash",
	//mode: "history",
	routes,
	scrollBehavior: () => ({ y: 0 }),//切换路由始终保持页面顶部
})

export default router
