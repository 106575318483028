import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import font from "./assets/font/iconfont.css"
import Re from "./assets/css/reset.css"
import "./assets/css/PF.css"
import request from "./utils/request.js"
import local from "@/utils/local";
import config from "@/utils/config.js"
import util from "@/utils/util.js"
import VueI18n from 'vue-i18n';
import * as echarts from 'echarts'

// 引入element的语言包
import locale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import cnLocale from 'element-ui/lib/locale/lang/zh-CN'
import jpLocale from 'element-ui/lib/locale/lang/ja'


import zh from '@/assets/lang/local_zh'
import en from '@/assets/lang/local_en'
import jp from '@/assets/lang/local_jp'

Vue.prototype.$echarts = echarts// 本地存储工具函数
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(VueI18n);

// bus

Vue.prototype.$request=request;
Vue.prototype.$local=local;
Vue.prototype.$bus = new Vue();
Vue.prototype.$config=config
Vue.prototype.$util=util
// const i18n = new VueI18n({
//   locale: 'jp', // 默认语言
//   messages:{
// 	  en: require('@/assets/lang/local_en'), // 英语
// 	  zh: require('@/assets/lang/local_zh'), // 汉语
// 	  jp: require('@/assets/lang/local_jp'), // 汉语
//   }
// })

// 创建i18n实例
const i18n = new VueI18n({
  // 设置默认语言
  locale: 'jp', // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    zh: {
      ...cnLocale,
      ...zh,
    },
    en: {
      ...enLocale,
      ...en,
    },
	jp:{
		...jpLocale,
		...jp
	}
  },
})

locale.i18n((key, value) => i18n.t(key, value))
export default i18n


Vue.config.productionTip = false

new Vue({
  router,
  el: '#app',
  i18n,
  render: h => h(App)
}).$mount('#app')
Vue.mixin({
	data() {
		return {
			lang:'',
			user_id:'',
			user_role:'',
			avatar_url:''
			
		};
	},
	created() {
		if(this.$local.get('lang')){
			this.lang=this.$local.get('lang');
		}
		this.user_id=this.$local.get('user_id');
		this.user_role=this.$local.get('user_role');
		this.avatar_url=this.$local.get('avatar_url');
	},
	destroyed() {
		//console.log('destroyed');
		this.loading=true;
	},
	methods:{
		//举报
		async jubao(id){
			this.$refs.jubao.open(id);
		},
		goback(){
			this.$router.go(-1);
		},
		toUserPage(id,role){
			if(role==0){
				if(id==this.$local.get('user_id')){
					this.$router.push({path:'/my'})
				}else{
					this.$router.push({path:'/people_page',query:{user_id:id}})
				}
				
			}else{
				if(id==this.$local.get('user_id')){
					this.$router.push({path:'/qy'})
				}else{
					this.$router.push({path:'/qypage',query:{user_id:id}})
				}
				
			}
		},
	}
})