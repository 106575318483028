<template>
  <div id="app" class="" :class="{meiryo:lang=='jp'}">  
  <router-view></router-view>

  </div>
</template>

<script>
  export default {
	data() {
		return {			
			lang:'jp',			
		};
	},
	created() {		
		if(this.$local.get('lang')){			
			this.lang=this.$local.get('lang');
		}
	}
  };
</script>

<style lang="less">
@font-face {
	font-family: "meiryo";
	src: url('./assets/font/Meiryo.ttf') format('truetype');
}
.meiryo {font-family: meiryo !important;}
*{
  margin: 0;
  padding: 0;
      
}

html,body{
  height: 100%;
  font-family: 'PF';min-width:1690px;
}

img{max-width: 100%;}
input[type=number]::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance: none !important;}
#app{
  height: 100%;
  // background-color: #212121;
}
.flex-center{display: flex;align-items: center;}
.flex-start{display: flex;align-items: flex-start;}
.flex-item{flex:1;}
.ellipsis{text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.sharemenu{position: absolute;left: 50%;top: 100%;z-index: 99;transform: translateX(-50%); padding-top: 12px;display: none;
	div{padding: 10px;font-weight: normal;font-size: 14px; background-color: #fff;border-bottom: 1px solid #eee;cursor: pointer;color: #333;position: relative;white-space: nowrap;
		
		&:first-child{border-radius: 5px 5px 0 0;
			&:before{content:"";border-bottom: 6px solid #fff;border-left: 6px solid transparent;border-right: 6px solid transparent;position: absolute;bottom:100%;left:50%;margin-left: -6px;}
		}
		&:last-child{border:none;border-radius: 0 0 5px 5px;}
	}
}
a{color:inherit}
.mmorebtn{position: relative;
	&:hover .sharemenu{display: block;}
}
.el-textarea__inner{padding-bottom: 20px;}
.el-input__count{line-height: 1.2}
</style>