module.exports =  {
	SCZPJ:"点击上传作品集",
	DFJFL:'新納品申請あり、ご確認ください',
	YXTS:'操作なしで提出する場合、１★と見なされます。',
	PINGLUN:'レコメンドする',
	CONFIRM2:'はい',
	CANCEL2:'いいえ',
	RIBEN:'日本',
	OTHERLINK:'其他',
	SKILL:'技能',
	LBIMGTIP:"「※イベント一覧部分に表示される画像」と記載",
	ZXIMGTIP:"「※イベント詳細画面内で表示される画像」と記載",
	TZXX:"通知消息",
	QBJHWC:"※却下されたので、もう一回納品してください",
	QBJHWC2:"※納品された方は納品完了をクリックしてください",
	YBJ:"已被拒",
	QRQXM:'确认取消吗',
	HDYJS:'活动已结束',
	BMRSYM:'报名人数已满',
	QDBMM:"确认要报名吗",
	YSQ:"已申请",
	JUBAO:"举报",
	QSRJBLY:"请输入举报理由",
	FAV:"收藏",
	QXZDQ:"请选择地区",
	ABOUTUS:[
		"昨今の働き方改革やジョブ型雇用への移行トレンドの中で、",
		"人々は、より自由で自分らしい働き方・生き方を志向するようになりました。",
		"一方、フリーランスや副業ワーカーは未だ“マイノリティ”として位置付けられ、",
		"一部では社会的信用度に乏しく、マイナスイメージを持たれていることも事実です。",
		"自由な働き方を選びたい。自分の実力を試したい。",
		"誰かの役に立ちたい。仲間と出会い、ともに成長していきたい…。",
		"我々は、そんなフリーランス・副業ワーカーのインフラとなり、",
		"市場活性化及び信用度向上の一助となることで、人々の挑戦を応援したいと考えています。"
	],
	
	TGS:"投稿数",
	FANS:"粉丝",
	QXZ:"请选择",
	QSR:"请输入",
	QSC:"请上传",
	FOLLOW:"关注",
	XUELI:"学历",
	HOPETIME:"希望劳动时间/天",
	MEITIAN:'(1日あたり)',
	EVERY:"每",
	HOPESTART:"希望开始时间",
	JIANZHONG:"件",
	JIAN:"件",
	REALORDER:"实际订单",
	REALORDER2:"实际订单",
	ALLAMOUNT:"累计金额",
	AGEOPEN:"年龄公开方式",
	EMAILPHONE:"邮箱/手机号",
	SEXOPEN:"性别公开方式",
	NAME:"名称",
	VAL:"内容",
	KSRQ:"开始日期",
	JSRQ:"结束日期",
	TJXMJL:"添加项目经历",
	QTXWZXX:"请填写完整信息",
	MIN:"以上",
	MAX:"以下",
	USEKEYWORD:"用关键字搜索",
	USEKEYWORD2:"名前で検索",
	RMPX:"热门排序",
	ZXFB:"最新发布",
	JZBMSJ:"截止报名时间",
	BYJN:"必要技能",
	TIME:"时间",
	YJ:"佣金",
	REN:"人",
	FEN:"分",
	FBSJ:"发布时间",
	RWJJ:"任务简介",
	TIAN:"天",
	HH:"时",
	MM:"分",
	SS:"秒",
	HJZ:"后截止",
	GZDZ:"工作地址",
	JOBTIME:{0:'天',1:'周',2:'月'},
	GZGX:"工作关系",
	JBLC:"接包流程",
	SQWCGZ:"申请完成工作",
	WCJSGZ:"完成结算工作",
	GXS:"个小时",
	YXZK:"运行状况",
	SJDJ:"时间单价",
	FSXX:"发送消息",
	FBZ:"发布者",
	QBGZ:"全部关注",
	QXGZ:"取消关注",
	ZHIYE:"职业",
	FB:"发布",
	FS:"发送",
	CKGD:"查看更多",
	YJDDL:"已经到底了",
	XXXQ:"学校详情",
	SSZY:"所属专业",
	YCJ:"已参加",
	BM:"报名",
	QXBM:"取消报名",
	START:"始",
	END:"终",
	SHZT:"审核状态",
	SSHDMC:"搜索活动名称",
	HDSQ:"活动时区",
	HDKSSJ:"活动开始时间",
	HDJSSJ:"活动结束时间",
	XSFS:"线上方式",
	HYID:"Alis会议ID",
	HYPWD:"Alis会议密码",
	TOALIS:"去Alis生成会议",
	HDLJ:"活动链接",
	ADR:"地址",
	CONFIRM:"确认",
	CANCEL:"取消",
	QRWC:"确认完成",
	SFWTMC:"三方委托名称",
	SXFW:"时薪范围",
	HIGH:"高",
	LOW:"低",
	QDWCGZ:"确定工作完成",
	QSRBHYY:"请输入驳回原因",
	TISHI:"提示",
	QRGYWCGZ:"请确定雇员已完成所有工作相关工作",
	QHDNBGZGL:"切换到内部工作管理",
	QI:"企",
	ZJ:"中介",
	GZWC:"工作完成",
	JJSJ:"拒绝时间",
	RC:"日程管理",
	BD:"沟通状况",
	HHQY:"整体印象",
	QRWCWORK:"请确定已完成所有工作相关工作",
	FSMMCZYZM:"发送密码重置验证码",
	SRFOURYZM:"请输入4位验证码",
	PWDRULE:'6到 16个单字节字母数字和符号',
	QDMM:"确定密码",
	RJYZM:"人机验证码",
	IAGREE:"我同意",
	ADRTIP:"订阅/发布工作时需要提供真实地址",
	NAMETIP:"订阅/发布工作时需要提供真实姓名",
	HYZ:"换一张",
	AND:"和",
	GRXXBHZC:"个人信息保护政策",
	YDTYZC:"请阅读并同意《使用条款》和《个人信息保护政策》",
	ACCOUNTTIP:"输入您注册时的电子邮或联系电话",
	PWDTIP:"输入登录密码",
	QSRACCOUNT:"输入您的电子邮箱或联系电话用于注册账号",
	GXXR:"感谢您对YOURS的信任",
	GZBH:"工作编号",
	NOMORE:"暂无更多",
	QDSCM:"确认删除吗",
	TJXXXQ:"推荐详情信息",
	GRYH:"个人用户",
	QYYH:"企业用户",
	
	index:{
		title1:'工作，伙伴，网络。',
		title2:'需要自由职业者的一切',
		slon1:'YOURS是为了让自由职业者更加闪耀而诞生的是新形式的SNS平台。',
		p1tit:'自由职业者・找到副业案件',
		p2tit:'自由职业者的实绩・信用可视化',
		p3tit:'通过SNS与朋友联系',
		p1txt:'处理X职种的自由职业者·副业案件，不仅仅是企业案件，合作代理X公司的案件也全部一览化。此外，还可以统一管理招聘/星探到收款的案件流程。',
		p2txt:'将自由职业者的经历、案件实绩、第三方评价、制作物等的简介全部集中在YOURS仪表板上。使作为自由职业者的职业积累成为可能，实现社会信用的可视化。',
		p3txt:'YOURS提供了超越作业匹配服务领域的自由职业者·副业工作者特化的SNS平台。自由职业者之间连接，可以进行开放的信息交换和社区形成。',
		index_one:"1",
		title3:"与现有作业匹配站点的不同",
		index_one_img:"1",
		ljzchy:"立即注册会员",
		qyzb:"企业注册",
		title4:"自由职业者、企业和公司职员们",
		title5:"今后，请采取更自由的工作方式。",
		slon2:"YOURS寻求更高质量的工作",
		slon3:"是面向所有人开发的服务。",
		slon4:"以下的各位，请一定要研究。",
		ystit1:"自由职业者・副业工作者们",
		ystit2:"公司职员们",
		ystit3:"企业的各位",
		ystit4:"创业者·经营者们",
		
		ystxt1:"想建立自由职业者、副业工作者之间的联系",
		ystxt2:"想扩大工作范围，积累经验",
		ystxt3:"想要实际案件的凭证",
		ystxt4:"我想找个人做一部分工作",
		
		ystxt5:"想轻松地知道有什么副业案件",
		ystxt6:"希望有关于副业的信息交换场所",
		ystxt7:"对不受公司束缚的生活感兴趣",
		ystxt8:"想为创业准备资金",
		
		ystxt9:"希望灵活地确保专业性高的人才",
		ystxt10:"通过咨询公司的话，费用感不合适",
		ystxt11:"指名购入优秀人材",
		ystxt12:"想防止录用的不匹配",
		
		ystxt13:"想在本职工作的同时做自己喜欢的工作",
		ystxt14:"想扩大人脉和网络",
		ystxt15:"想委托自由职业者、副业工作者工作",
		ystxt16:"想宣传自己公司的事业",
		zpxx:"招聘信息",
		weizhi:"位置",
		xinzi:"薪资",
		title6:"我有一份很适合你的工作",
		slon5:"正在注册各种职业的云工作者。",
		slon6:"与工作的规模、速度感、预算相匹配",
		slon7:"你可以找优秀的人才，组队。",
		
		zixun:"咨询",
		zixun1:"咨询战略咨询",
		zixun2:"财务和会计咨询",
		zixun3:"人事和组织咨询",
		zixun4:"业务流程咨询",
		zixun5:"物流、SCM咨询",
		zixun6:"营销咨询",
		zixun7:"研究，研究",
		zixun8:"IT咨询",
		
		shangye:"商业",
		shangye1:"经营企划、经营战略立案",
		shangye2:"新事业企划、事业开发",
		shangye3:"并购、合作",
		shangye4:"财务、会计、会计",
		shangye5:"内部审核和内部控制",
		shangye6:"销售",
		
		renshi:"人事",
		renshi1:"聘用",
		renshi2:"组织开发和制度设计",
		renshi3:"劳务、工资",
		
		it:'IT・开发',
		it1:'Web应用程序开发',
		it2:'移动应用程序开发',
		it3:'基础设施设计和构建',
		it4:'安全措施',
		it5:'系统运行维护',
		it6:'ERP导入、CRM导入',
		it7:'数据分析和数据利用',
		it8:'网络主管',
		it9:'PMO',
		
		sheji:'设计',
		sheji1:'图形设计',
		sheji2:'WebUI设计',
		sheji3:'WebUX设计',
		sheji4:'产品设计',
		sheji5:'包装设计',
		sheji6:'艺术指导',
		sheji7:'创意指导',
		sheji8:'空间・店铺设计',
		sheji9:'室内设计',
		
		scyx:"市场营销",
		scyx1:"消隐",
		scyx2:"广告宣传",
		scyx3:"商品企划・开发",
		scyx4:"网络广告运营",
		scyx5:"数字营销",
		
		cyzz:"创意制作",
		cyzz1:"动画、影像制作、编辑",
		cyzz2:"摄影、编辑",
		cyzz3:"创建主页",
		cyzz4:"照明",
		cyzz5:"复印照明",
		cyzz6:"插图开发",
		cyzz7:"乐曲制作",
		
		qita:"其他",
		qita1:"翻译、口译",
		qita2:"摄影模型",
		qita3:"数据输入",
		qita4:"各种代理",
		
		title7:"将技能、经历、优势可视化",
		title8:"YOURS仪表盘",
		slon8:"以工作场所、时间、希望的价格带为首",
		slon9:"在仪表板上可视化技能和业绩等。",
		slon10:"实现最适合的工作的匹配。",
		
		title9:"发出自己，与世界相连。",
		slon10:"工作的募集和应募自不必说，就像SNS一样",
		slon11:"一边自由地表现你的个性",
		slon12:"我们可以沟通。",
		
		title10:"自己的工作方式由自己决定。",
		title11:"YOURS通过工作闪耀",
		title12:"和朋友相遇",
		title13:"为了想享受人生的人",
		title14:"是新的“栖息地”。",
		
		slon13:"以自由的生活方式、工作方式为志向，自己掌握人生的方向盘"
		
	},
	header:{
		ZGZ:"找工作",
		XZRC:"寻找人才",
		LJ:"动态",
		FBGZ:"发布工作",
		FBHD:"发布活动",
		HD:"活动",
		QYDL:"企业登录",
		GRDL:"个人登录",
		QYZC:"企业注册",
		GRZC:"个人注册",
		WDZY:"我的主页",
		HDZX:"活动中心",
		RWGL:"任务管理",
		GZZX:"工作中心",
		WDGZ:"我的关注",
		XGMM:"修改密码",
		QHBJ:"切换背景",
		TCDL:"退出登录",
		
		YSZC:"隐私政策",
		SYTK:"使用条款",
		CLGRXX:"处理个人信息",
		DL:"登录",
		TDJYFBS:"特定交易法表述",
	},
	// 编辑企业
	QY_msg:{
		JBXX:"基本信息",
		FGK:"非公开",
		QYMC:"企业名称",
		GK:"公开",
		SSHY:"所属行业",
		QXZ:"请选择",
		SSXX:"上市信息",
		SSQY:"上市企业",
		FSSQY:"非上市企业",
		QYGM:"企业规模",
		XSGM:"销售规模",
		YGGM:"员工规模",
		ZFFS:"支付方式",
		PJXS:"评价显示",
		QYMC:"企业名称",
		SSZT:"上市状态",
		WBLJ:"外部链接",
		QYGW:"企业官网",
		LXDH:"联系电话",
		YZBM:"邮政编码",
		DDFX:"都道府县",
		DWDZ:"定位地址",
		XQDZ:"详细地址",
		QYLOGO:"企业LOGO",
		TPSC:"图片上传",
		QYJJ:"企业简介",
		ZZXX:"资质信息",
		YYZC:"营业执照",
		YYZCMSG:"提出すると提出済みマークが表示されます",
		QTZJ:"其他证件",
		DJSC:"点击上传",
		BCXX:"保存信息",
	},
	// 编辑
	my_msg:{
		QZXX:"求职信息",
		RZSJ:"入职时间",
		KSSJ:"开始时间",
		XWXZ:"希望时薪",
		CQSJ:"出勤时间",
		ATJS:"按天计时",
		ZZJ:"按周计",
		MZ:"每周",
		XS:"小时",
		AYJS:"按月计时",
		XWGW:"希望岗位",
		XZNL:"选择能力",
		TDTZSX:"拖动调整顺序",
		BGFS:"办公方式",
		KCC:"可出差",
		KYC:'可远程',
		KKQ:"可考勤",
		KWP:"可外派",
		XLDJ:'学历等级',
		SKRXM:'收款人姓名',
		QSRSKRXM:'请输入收款人姓名',
		SKRDZ:'收款人地址',
		QSRSKRDZ:'请输入收款人地址',
		SKZH:'收款账号',
		QSRSKZH:'请输入收款账号',
		SKYH:'收款银行',
		QSRSKYH:'请输入收款银行',
		SKHB:'收款货币',
		QSRHKHBDW:'请输入收款货币单位',
		QSRSwiftcode:'请输入Swiftcode',
		ZDMC:'字段名称',
		ZDNR:'字段内容',
		TJZDYZD:'添加自定义字段',
		XXJL:'学习经历',
		TJXXJL:'添加学习经历',
		XXMC:'学校名称',
		SXZY:'所学专业',
		QSRXXMC:'请输入学习名称',
		QSRZYMC:'请输入专业名称',
		ZXSJ:'在校时间',
		KSSJ:'开始时间',
		JSSJ:'结束时间',
		ZWXC:'职位薪酬',
		CZ:'初中',
		ZXJL:'在校经历',
		QSRNSFZDNR:'请输入您所负责的部分',
		GZJL:'工作经历',
		TJGZJL:'添加工作经历',
		QSRGSMC:'请输入公司名称',
		SSZW:'所属职位',
		QSRXMGSGSMC:'请输入项目归属公司名称',
		ZZSJ:'在职时间',
		GZNR:'工作内容',
		GZCJ:'工作成绩',
		QSRNSFZDBFDCG:'请输入您所负责的部分的成果',
		XMJL:'项目经历',
		XMMC:'项目名称',
		QSRXMMC:'请输入项目名称',
		XMSS:'项目所属',
		XMSJ:'项目时间',
		KSSJ:'开始时间',
		FZSX:'负责事项',
		XMCG:'项目成果',
		BCXX:'保存信息',
		JBXX:'基本信息',
		ZCXX:'注册信息',
		CSRQ:'出生日期',
		NL:'年龄',
		S:'岁',
		YWNL:'业务能力',
		XZNL:'选择能力',
		ZYBS:'职业背书',
		TZTZSX:'拖动调整顺序',
		LALBB:'例:阿里巴巴电商事业部总监',
		XB:'性别',
		MAN:'男性',
		WOMAN:'女性',
		BXHD:'不想回答',
		XBGKXZ:'性别公开选择',
		NC:'昵称',
		GK:'公开',
		FGK:'非公开',
		ZSXM:'真实姓名',
		ENXM:'英文名',
		GKZSXM:'公开真实姓名',
		LXFS:'联系方式',
		YZBM:'邮政编码',
		DDFX:'都道府县',
		DWDZ:'定位地址',
		XXDD:'详细地址',
		BCXX:'补充信息',
		TX:'头像',
		ZPJ:'作品集',
		TPSC:'图片上传',
		WBLJ:'外部链接',
		GRJJ:'个人简介',
		QR:'确认',
	},
	// 动态
	home:{
		DTGC:'动态广场',
		GZDT:'关注动态',
		WDDT:'我的动态',
		TJYH:'推荐用户',
		FDXX:'发消息',
		FH:'返回',
		YGZ:'已关注',
		WGZ:'未关注',
		FBNDPL:'发布你的评论',
		CKQBPL:'查看全部评论',
		XX:'消息',
		HF:'回复',
		SS:'搜索',
	},
	// 发布活动
	add_activity:{
		HDZX:'活动中心',
		SSHD:'搜索活动',
		YNCJ:'由你创建',
		GXQD:'感兴趣的',
		CYD:'参与的',
		WQHD:'往期活动',
		FBHD:'发布活动',
		HDMC:'活动名称',
		HDSJ:'活动时间',
		SQ:'时区',
		Z:'至',
		HDDZ:'活动地点',
		XS:'线上',
		XX:'线下',
		DTXD:'地图选点',
		HDRS:'活动人数',
		HDDD:'参加限制',
		WXZ:'无限制',
		XSH:'需审核',
		LBTP:'列表图片',
		TJBL:'推荐比例',
		ZSTP:'展示图片',
		GRJJ:'个人简介',
		HDXQ:'活动详情',
		QX:'取消',
		HY:'会议',
		QSRAlisHYID:'请输入Alis会议室ID',
		QSRAlisHYMM:'请输入Ais会议密码',
		QAlisSCHY:'去Ais生成会议',
		QSRXSHDLJ:'请输入线上活动链接',
		QR:'确认',
	},
	job:{
		ZZZX:'工作中心',
		QHDWBGZGL:'切换到外部工作管理',
		SSRW:'搜索任务',
		DKS:'待开始',
		JXZ:'进行中',
		YWC:'已完成',
		FBGZ:'发布工作',
		ZYXZ:'职业选择',
		ZDKXZZZ:'最多可选择5种职业',
		SJS:'设计师',
		ZJ:'作家',
		JZ:'记者',
		WNZZ:'网络制作',
		JR:'金融',
		GZMC:'工作名称',
		SXYWNL:'所需业务能力',
		GZGS:'工作归属',
		ZZWT:'直接委托',
		SFWT:'三方委托',
		QSRWTDSFDMC:'请输入委托第三方的名称',
		JCFS:'计酬方式',
		AGZSC:'按工作时长',
		GDYJ:'固定佣金',
		ACJF:'按次计费',
		YJFW:'佣金范围',
		Z:'至',
		D:'低',
		G:'高',
		GZSJ:'工作时间',
		GZLGD:'工作量固定',
		GZSJGD:'工作时间固定',
		CQGZ:'长期工作',
		CQSJ:'出勤时间',
		ATJS:'按天计时',
		AZJS:'按周计时',
		AYJS:'按月计时',
		GZDD:'工作地点',
		HW:'海外',
		GZFS:'工作方式',
		YC:'远程',
		XX:'线下',
		BX:'不限',
		SFCC:'是否出差',
		XYCC:'需要出差',
		WXCC:'无需出差',
		XQRS:'需求人数',
		SFJP:'其它',
		PTZM:'普通招募',
		JP:'急聘',
		GZXX:'工作性质',
		FY:'副业',
		JZRQ:'截止日期',
		XGWJ:'相关文件',
		CYKXZ:'参与者可下载',
		SYRKXZ:'所有人可下载',
		YoursYXRP:'Yours原型RP',
		DJSC:'点击上传',
		GZJJ:'工作简介',
		GZSM:'工作说明',
	},
	login:{
		QYZC:'企业注册',
		GRZC:'个人注册',
		LJDL:'立即登录',
		BCDLZT:'保持登录状态',
		WJDLMM:'忘记登录密码',
		QTDLFS:'其他登录方式',
		QTDLFS2:'用以下账户登录',
		CJXZH:'创建新账号',
		QSRNZCSDDZYX:'请输入您注册时的电子邮箱或者联系电话',
		QSRNDMM:'请输入您的Yours登录密码',
		
	},
	work:{
		QZRQ:'工作日期',
		BHWC:'驳回完成',
		QDGZWC:'确定工作完成，雇员申请完成工作，请在三个工作日内确定文件是否符合要求',
		WCGZ:'完成工作',
		JFWJXZ:'交付文件下载',
		HTQD:'合同签订',
		JFGZ:'交付工作',
		GZJS:'工作结算',
		GZDP:'工作点评',
		ZKCKGD:'展开查看更多雇员进度',
		JN:'技能',
		ZL:'质量',
		GT:'沟通',
		GX:'关系',
		JD:'进度',
		SJYJ:'实际佣金',
		QSRPJNR:'请输入评价内容',
		QSRSJJFYJ:'请输入实际支付的佣金用以做记录',
		JDPF:'确认评分',
		FS:'发送',
		GZXZ:'工作交付',
		LSJF:'历史交付',
		SCDGZ:'收藏的工作',
		JXZDGZ:'进行中的工作',
		SQZDGZ:'申请中的工作',
		WQGZ:'往期工作',
		QD:'确认',
		QQDYWC:'请确定已完成所有相关工作',
		SQZ:'申请中',
		YJJ:'已拒绝',
		NDPJ:'您的评价',
		WJSJ:'完成时间',
		WBGZ:'外部工作',
		JHDZCJ:'切换到自创建工作管理',
		QSRHZGCZ:'请输入合作过程中想说的吧',
		PJNYGZHZ:'评价您与雇主合作的感受',
		QXSC:'取消收藏',
	},
	job_detail:{
		WTR:'委托人',
		FR:'法人',
		GR:'个人',
		TC:'类型',
		SCYX:'市场营销',
		SJZZXG:'设计制作相关',
		QT:'其他',
		ZX:'咨询',
		RS:'人事',
		WTFS:'委托方式',
		XM:'项目',
		GDBCZ:'固定报酬制',
		KSL:'可商量',
		SJDJZ:'时间单价制',
		JYRW:'基于任务',
		BC:'报酬',
		WZD:'未指定',
		YX:'以下',
		QTSX:'其他筛选',
		KYC:'可远程',
		JP:'急聘',
		FSZM:'复数募集',
		KJZ:'可兼职',
		JCXX:'基础信息',
		GZPF:'雇主评分',
		BMQK:'报名情况',
		BMRS:'报名人数',
		GDYJ:'固定佣金',
		SCRS:'收藏人数',
		QDRS:'确定人数',
		SQGZ:'申请工作',
		DDXL:'等待贤良',
		TXSJZ:'*天*小时截止',
		BMJZ:'报名截止',
		ZMYQ:'招募要求',
		GZXQ:'工作详情',
		DJXZ:'点击下载',
		XBLC:'选拔流程',
		GZXD:'雇主选定',
		QDHT:'签订合同',
		KSGZ:'开始工作',
		JFGZ:'交付工作',
		SQWC:'申请完成',
		JYWC:'交易完成',
		RWJD:'任务进度',
		JFSM:'交付说明',
		SYYMTJWC:'所有页面提交完成，请尽快结算工作',
		JFSJ:'交付时间',
		WJSC:'文件上传',
		JFSM:'交付说明',
		ZSJGHXYM:'这是几个核心页面的设计，看看风格是否满意',
	},
	activity:{
		HDLB:'活动列表',
		FXHD:'发现活动',
		HDXS:'活动形式',
		HDZT:'活动主题',
		QB:'全部',
		PXFS:'排序方式',
		ZNPX:'智能推荐',
		CJ:'参加',
		HDXX:'活动信息',
		HDGY:'活动概要',
		FQZ:'发起者',
		FQSJ:'发起时间',
		HDSJ:'活动时间',
		WYCJ:'我要参加',
		JHDD:'集合地点',
		
		HDRS:'活动人数',
		CJXZ:'参加限制',
		HDJJ:'活动简介',
		BJHD:'编辑活动',
		BJ:'编辑',
		SC:'删除',
		CYZ:'参与者',
		GXQD:'感兴趣的',//字段名修改
		DSH:'待审核',
		YSH:'已审核',
		QQDSC:'请确认删除',
		QF:'群发',
		QFXX:'群发消息',
		QSRQFDXXNR:'请输入群发的消息内容',
		QFXXGCYZ:'群发消息给参与者',
		GLHD:'管理活动',
		YJS:'已结束',
		GXQ:'感兴趣',
		YBM:'已报名',
		YCY:'已参与',
		NQDYQXBMHD:'您确定要取消报名活动',
		YNCJ:'由你创建',
		WCYD:'我参与的',
		YCHD:'移除活动',
		NQDYSCBMHD:'您确定要删除报名活动',
		NQDYYCHD:'你确定要移除活动',
		TP:'图片',
		SP:'视频',
		HD:'活动',
		CKQBPL:'查看全部评论',
		YSMXFXGDJD:'有什么想分享给大家的',
	},
	QY:{
		YRZ:'已认证',
		HYGS:'行业归属',
		RYGM:'人员规模',
		PFCS:'评分次数',
		GYPF:'雇员评分',
		QYGW:'企业官网',
		ZT:'状态',
		QYJJ:'企业简介',
		AJPJ:'案件评价',
		QBDT:'全部动态',
		WZSPJ:'未展示评价',
		YZSPJ:'已展示评价',
		XPLMRSFZS:'新评论默认是否展示',
		YC:'隐藏',
		ZS:'展示',
		JP:'急聘',
		GDXZ:'固定薪资',
		WCSJ:'完成时间',
		PF:'评分',
		FBRWS:'发布任务数',
		PJXC:'平均薪酬',
		MFZC:'免费注册',
		YYZH:'已有账号，立即登录',
		ZCCWHY:'注册成为会员，即视为您同意',
		ZCCWHY2:'。',
		XYB:'下一步',
		FH:'返回',
		CXFS:'重新发送',
		FHCXFS:'返回重新发送',
	},
	task:{
		GYCK:'雇员查看',
		JDCK:'进度查看',
		GZPJ:'工作评价',
		GZXX:'工作信息',
		GZMC:'工作名称',
		FQSJ:'发起时间',
		XQRS:'需求人数',
		GZYJ:'工作佣金',
		ZSYQ:'证书要求',
		ZNYQ:'技能要求',
		BJGZ:'编辑工作',
		BJ:'编辑',
		GZJJ:'工作简介',
		SQZ:'申请者',
		MTZ:'面谈中',
		YJJ:'已拒绝',
		YTG:'已通过',
		DDMS:'等待面谈',
		GGZZZJXZ:'该工作正在进行中，无法删除',
		SQJSGZ:'申请结算工作',
		WCJSGZ:'完成结算工作',
		SQJS:'申请结算',
		WCJS:'完成结算',
		ZDPF:'自动评分',
		TDFS:'他的粉丝',
		TDGZ:'他的关注',
		ZKCKGDRW:'展开查看更多任务',
		ZWGD:'暂无内容',
		ZWGDFS:'暂无更多粉丝',
		ZWGDGZ:'暂无更多关注',
		QQDQXGZ:'请确认取消关注',
		XGZDFH:'需关注对方后，才能发送消息',
		SF:'三方',
		XMGL:'项目管理',
		LT:'聊天',
		JNPG:'技能评估',
		SJPG:'评价',
		DDPG:'收到的评价',
		CGDDPG:'评价别人',
		CPZH:'作品集',
		TJXXXX:'推荐详细信息',
		TJ:'推荐',
		XQXX:'详情信息',
		JB:'接包',
		FB:'发包',
		GX:'更新',
		XXXQ:'学习详情',
		XXMC:'学校名称',
		GSMC:'公司名称',
		GWMC:'岗位名称',
		GWXC:'岗位薪酬',
		RZSJ:'任职时间',
		GZNR:'工作内容',
		ZYMC:'专业名称',
		XMSS:'项目所属',
		XMSJ:'项目时间',
		FZSX:'负责事项',
		DZYXDZ:'电子邮件地址',
		DZJZMSZ:'8到16个单字节字母数字和符号',
		MM:'密码',
		QDMM:'确认密码',
		LN:'年龄',
		RJYZ:'人机验证',
		QDZC:'确认注册',
		WTYSYTK:'我同意使用条款和个人信息保护政策',
		WDGZ:'我的关注',
		WDFS:'我的粉丝',
		TJGZ:'推荐工作',
		FGK:'非公开',
		YZBM:'邮政编码',
		TJHD:'推荐活动',
		DWDZ:'定位地址',
		CSRQ:'出生日期',
		QXZCSRQ:'请选择出生日期',
		ZYBS:'职业背书',
		BMCJ:'报名参加',
		GZLM:'工作类目',
		RWL:'任务量',
		SYXW:'剩余席位',
		JJDZ:'聚集地址',
		HYH:'换一换',
		HG:'回关',
		YHG:'已互关',
		QBFS:'全部粉丝',
		SSYH:'搜索用户',
		QDBZGZCR:'确定不在关注此人',
		JDJTGYXJXFK:'进度将通过邮箱进行反馈，请确认的您的邮箱信息',
		XGYX:'修改邮箱',
		XG:'修改',
		DZ:'点赞',
		PL:'评论',
		PL2:'评论',
		PL3:'评论',
		
		FX:'分享',
		HF:'回复',
		RQ:'日期',
		PSY:'评审员',
		PF:'评分',
		XMLX:'项目类型',
	},
	other:{
		GX:'更新',
		KYC:'可远程',
		FSMC:'复数募集',
		KJZ:'可兼职',
		WTFS:'委托方式',
		TC:'体裁',
		SCYX:'市场营销',
		SJ:'设计',
		ZZXG:'制作相关',
		QT:'其他',
		ZX:'咨询',
		RS:'人事',
		SY:'商业',
		FR:'法人',
		ZJAJ:'中介案件',
		RM:'热门',
		ZX:'最新',
		YJGD:'佣金高低',
		G:'高',
		D:'低',
		GJZSS:'关键词搜索',
		JBMSJ:'距报名时间',
		JRWKSSJ:'距任务开始时间',
		BC:'保存',
		XYRS:'需要人数',
		CZMM:'重置密码',
		FHDL:'返回登录',
		FSYZM:'发送验证码重置密码',
		QSRNZCDDDYJDZ:'请输入您注册的电子邮件地址。一封包含密码更改页面 URL 的电子邮件将发送到您的电子邮件地址',
		DZYX:'电子邮件',
		SJHM:'手机号码',
		FSCG:'发送成功',
		QDDZYJ:'确认电子邮件验证码已发送到注册的电子邮件',
		QDDZYJ2:'确认短信验证码已发送到注册的手机号码',
		QDDZYJ4:'記載されている４桁の認証コードをご入力ください',
		QDDZYJ3:'※承認コードが届かない場合は、以下「再送」を押し、再度受け取りをしてください。',
		QSRGGHDMM:'请输入更改后的密码',
		QCZSRMMYJXQD:'请再次输入密码以进行确认',
		GXDLXX:'更新登陆信息',
		PFPX:'评分排序',
		DLSJPX:'登录时间排序',
		XCPX:'时薪排序',
		ZCSJ:'注册时间',
		CJSJ:'创建时间',
		SQRS:'申请人数',
		CKXQ:'查看详情'
	}
}