// 引入
import axios from "axios";
import qs from "qs";
import router from '@/router'
// 引入弹框组件
import { Message } from "element-ui";
// 引入local
import local from "@/utils/local";
import config from "@/utils/config.js"
// 请求拦截器
axios.interceptors.request.use(config => {
 // console.log(config)
  // 请求头携带token
  config.headers.Authorization = `Bearer ` + local.get("t_k");
 
  // config.headers.Authorization =  local.get("t_k");
  let lang='jp';
  if(local.get('lang')){
	if(local.get('lang')=='zh'){
		lang='zh-CN';
	}else{
		lang=local.get('lang');
	}
  }
  config.headers['Accept-Language'] =lang;
  
  return config;
});

// 响应拦截器
axios.interceptors.response.use(
    function (response) {
		if(response.status==200){
			
		}else{
			//Message.error(response.data.message)
			
		}
        return response;
    },
    function (error) {
		if (error.response) {			
			switch (error.response.status) {
				case 401:
					// console.log('未登录');				
					// router.push({path:'/login'});
					break;
				
				default:
					Message.error(error.response.data.message)
				
			}
		}
		return Promise.reject(error.response.data)
    }
);



// 统一配置
axios.defaults.baseURL = config.baseUrl;
axios.defaults.timeouts = 5000;

export default {
	txim(params={},options={showfail:0}){
		return new Promise((resolve, reject) => {
		  axios.post('https://yours.online/app/tencent/index', params).then(res => {
				
				if(res.data.code==0){
					resolve(res.data)
				}else{
					if(options.showfail==1){
						Message.error(res.data.message)
					}
					reject(res.data)
				}
		    })
		    .catch(err => {
		      reject(err); //失败
		    });
		});
	},
	reg(params={},options={showfail:0}){
		return new Promise((resolve, reject) => {
		  axios.post('https://yours.online/app/tencent/register', params).then(res => {
				
				if(res.data.statusCode==200){
					resolve(res.data)
				}else{
					if(options.showfail==1){
						Message.error(res.data.message)
					}
					reject(res.data)
				}
		    })
		    .catch(err => {				
		      reject(err); //失败
		    });
		});
	},
    get(url, params = {},options={showfail:1,isimg:0,islg:1}) {
        return new Promise((resolve, reject) => {
          axios.get(url, {params}).then(res => {
				if(options.isimg==1){
					resolve(res.data)
					return;
				}
				if(res.data.statusCode==200){
					resolve(res.data)
				}else{
					if(options.showfail==1&&res.data.statusCode!=50010){
						Message.error(res.data.message)
					}
					reject(res.data)
				}
            })
            .catch(err => {
				//console.log(11111111111,err);
				if(err.statusCode==401){
					if(options.islg==1){
						let lang=local.get('lang');
						local.clear();
						sessionStorage.clear();
						local.set('lang',lang);
						sessionStorage.clear();
						router.push({path:'/login'});
					}
					
				}
              reject(err); //失败
            });
        });
    },
    
    post(url,params={},options={showfail:1,islg:1}){
        return new Promise((resolve,reject)=>{
            axios.post(url,params)
            .then(res=>{
				if(res.data.statusCode==200){
					resolve(res.data)
				}else{
					if(options.showfail==1&&res.data.statusCode!=50010){
						Message.error(res.data.message)
					}
					reject(res.data)
				}
            })
            .catch(err=>{
				if(err.statusCode==401){
					if(options.islg==1){
						let lang=local.get('lang');
						local.clear();
						sessionStorage.clear();
						local.set('lang',lang);
						router.push({path:'/login'});
					}
				}
                reject(err)
            })
        })
    },
}