module.exports =  {
	SCZPJ:"ポートフォリオアップロード",
	DFJFL:'新納品申請あり、ご確認ください',
	YXTS:'操作なしで提出する場合、１★と見なされます。',
	PINGLUN:'レコメンドする',
	CONFIRM2:'はい',
	CANCEL2:'いいえ',
	RIBEN:'日本',
	OTHERLINK:'その他',
	SKILL:'スキル',
	LBIMGTIP:"※イベント一覧部分に表示される画像",
	ZXIMGTIP:"※イベント詳細画面内で表示される画像",
	TZXX:"通知一覧",
	QBJHWC:"※却下されたので、もう一回納品してください",
	QBJHWC2:"※納品された方は納品完了をクリックしてください",
	YBJ:"不合格",
	QRQXM:'キャンセルをしますか',
	HDYJS:'アクティビティが終了しました',
	BMRSYM:'募集は終了いたしました',
	QDBMM:"申し込みをしますか",
	YSQ:"応募済み",
	JUBAO:"通報する",
	QSRJBLY:"通報理由を入力してください",
	FAV:"お気に入り",
	QXZDQ:"地域を選択",
	ABOUTUS:[
		"昨今の働き方改革やジョブ型雇用への移行トレンドの中で、",
		"人々は、より自由で自分らしい働き方・生き方を志向するようになりました。",
		"一方、フリーランスや副業ワーカーは未だ“マイノリティ”として位置付けられ、",
		"一部では社会的信用度に乏しく、マイナスイメージを持たれていることも事実です。",
		"自由な働き方を選びたい。自分の実力を試したい。",
		"誰かの役に立ちたい。仲間と出会い、ともに成長していきたい…。",
		"我々は、そんなフリーランス・副業ワーカーのインフラとなり、",
		"市場活性化及び信用度向上の一助となることで、人々の挑戦を応援したいと考えています。"
	],
	
	
	TGS:"投稿数",
	FANS:"フォロワー",
	QXZ:"選択してください",
	QSR:"入力してください",
	QSC:"アップロードしてください",
	FOLLOW:"フォロー",
	XUELI:"学歴",
	HOPETIME:"稼働時間／日",
	MEITIAN:'(1日あたり)',
	EVERY:"1",
	HOPESTART:"希望開始時期",
	JIANZHONG:"件中",
	JIAN:"件",
	REALORDER:"発注実績",
	REALORDER2:"受注実績",
	ALLAMOUNT:"累計金額",
	AGEOPEN:"年齢公開",
	EMAILPHONE:"E-mail/携帯",
	SEXOPEN:"性別公開について",
	NAME:"名称",
	VAL:"内容",
	KSRQ:"開催日時",
	JSRQ:"終了時間",
	TJXMJL:"プロジェクトを追加",
	QTXWZXX:"必須項目を入力してください",
	MIN:"以上",
	MAX:"以下",
	USEKEYWORD:"キーワード検索",
	USEKEYWORD2:"名前で検索",
	RMPX:"人気順",
	ZXFB:"最新掲載順",
	JZBMSJ:"申込締切順",
	BYJN:"必要なスキル",
	TIME:"期間",
	YJ:"報酬",
	REN:"人",
	FEN:"分間",
	FBSJ:"掲載時間",
	RWJJ:"案件概要",
	TIAN:"日間",
	HH:"時間",
	MM:"分",
	SS:"秒",
	HJZ:"後に締め切り",
	GZDZ:"勤務地",
	JOBTIME:{0:'日',1:'週',2:'月'},
	GZGX:"その他",
	JBLC:"受注フロー",
	SQWCGZ:"納品報告／案件完了",
	WCJSGZ:"決済完了",
	GXS:"期間",
	YXZK:"稼働状況",
	SJDJ:"時間単価",
	FSXX:"メッセージを送信",
	FBZ:"掲載者",
	QBGZ:"フォロー中",
	QXGZ:"フォローを解除",
	ZHIYE:"職種",
	FB:"掲載",
	FS:"つぶやく",
	CKGD:"もっと閲覧",
	YJDDL:"以上です",
	XXXQ:"学校詳細",
	SSZY:"ご専攻",
	YCJ:"申込済",
	BM:"申込",
	QXBM:"申込取消し",
	START:"開始",
	END:"完了",
	SHZT:"審査ステータス",
	SSHDMC:"キーワード検索",
	HDSQ:"タイムゾーン",
	HDKSSJ:"開始日時",
	HDJSSJ:"終了日時",
	XSFS:"パターン",
	HYID:"Alis会議ID",
	HYPWD:"Alisパスワード",
	TOALIS:"Alis会議開始へ",
	HDLJ:"リンク",
	ADR:"アドレス",
	CONFIRM:"確認",
	CANCEL:"キャンセル",
	QRWC:"納品確認",
	SFWTMC:"第三者委託先",
	SXFW:"時給幅",
	HIGH:"高い",
	LOW:"低い",
	QDWCGZ:"納品内容の確認完了",
	QSRBHYY:"却下理由を記入してください",
	TISHI:"注意",
	QRGYWCGZ:"納品内容の確認完了通知をお送りいたします",
	QHDNBGZGL:"掲載一覧へ切り替え",
	QI:"企業",
	ZJ:"仲介",
	GZWC:"納品完了申請",
	JJSJ:"拒否時間",
	RC:"スケジュール管理",
	BD:"コミュニケーション管理",
	HHQY:"全体印象",
	QRWCWORK:"全て完了しましたか？",
	FSMMCZYZM:"認証コードを送信",
	SRFOURYZM:"４桁の認証コードをご記入ください",
	PWDRULE:'6～16桁英数字と符号の組み合わせ',
	QDMM:"確認",
	RJYZM:"認証コード",
	IAGREE:"に同意する",
	ADRTIP:"ジョブ掲載や案件管理機能を利用するにあたり、住所入力が必要となります。",
	NAMETIP:"ジョブ収集/掲載に本名の入力が必要となります",
	HYZ:"更新",
	AND:"と",
	GRXXBHZC:"プライバシーポリシー",
	YDTYZC:"《利用規約》と《プライバシーポリシー》を閲覧の上に同意してください",
	ACCOUNTTIP:"登録したE-mailか携帯番号を入力してください",
	PWDTIP:"ログインパスワードを入力してください",
	QSRACCOUNT:"Emailアドレスもしくは電話番号を入力",
	GXXR:"Yoursへ登録いただき、ありがとうございます！",
	GZBH:"ジョブID",
	NOMORE:"以上です",
	QDSCM:"削除しますか",
	TJXXXQ:"レコメンド詳細",
	GRYH:"個人利用者",
	QYYH:"法人利用者",
	
	index:{
		title1:'仕事、仲間、ネットワーク。',
		title2:'フリーランスが必要とするすべてが、ここに。',
		slon1:'YOURSはフリーランスの人々がもっと輝くために生まれた新しいかたちのSNSプラットフォームです。',
		p1tit:'フリーランス・副業案件が見つかる',
		p2tit:'フリーランスの実績・信用を可視化',
		p3tit:'SNSを通じて仲間と繋がる',
		p1txt:'X職種のフリーランス・副業案件を取り扱い、企業案件のみならず提携エージェントX社の案件も全て一覧化。また、求人応募/スカウト～入金までの案件プロセスを一括管理することを可能に。',
		p2txt:'フリーランスの経歴、案件実績、第三者評価、作成物等のプロフィールをYOURSダッシュボードに全て集約。 フリーランスとしてのキャリアの蓄積を可能にし、社会的信用の可視化を実現。',
		p3txt:'YOURSはジョブマッチングサービスの域を超えたフリーランス・副業ワーカー特化のSNSプラットフォームを提供。フリーランス同士がつながり、オープンな情報交換やコミュニティ形成が可能に。',
		index_one:"2",
		title3:"既存のジョブマッチングサイトとの違い",
		index_one_img:"2",
		ljzchy:"今すぐ会員登録する",
		qyzb:"企業の方はこちら",
		title4:"フリーランスも、企業や会社員の方々も、",
		title5:"これからは、より自由な働き方を。",
		slon2:"YOURSは、より質の高い仕事を求める",
		slon3:"すべての方々に向けて開発されたサービスです。",
		slon4:"以下のような方々は、ぜひご検討ください。",
		ystit1:"フリーランス・副業ワーカーの方々",
		ystit2:"会社員の方々",
		ystit3:"企業の方々",
		ystit4:"起業家・経営者の方々",
		
		ystxt1:"フリーランス・副業ワーカー同士のつながりを作りたい",
		ystxt2:"仕事の幅を広げ、キャリアを積み上げていきたい",
		ystxt3:"案件実績のクレデンシャルが欲しい",
		ystxt4:"誰かに一部の仕事を依頼したい",
		
		ystxt5:"どんな副業案件があるのか気軽に知りたい",
		ystxt6:"副業に関する情報交換の場が欲しい",
		ystxt7:"会社に縛られずに生きることに興味がある",
		ystxt8:"起業のための準備資金を作りたい",
		
		ystxt9:"専門性の高い人材を柔軟に確保したい",
		ystxt10:"コンサル会社を通すと費用感が合わない",
		ystxt11:"優秀な人材を指名買いしたい",
		ystxt12:"採用のミスマッチを防ぎたい",
		
		ystxt13:"本業の傍らで好きな仕事をしたい",
		ystxt14:"人脈やネットワークを広げたい",
		ystxt15:"フリーランス・副業ワーカーに仕事を依頼したい",
		ystxt16:"自社の事業を宣伝したい",
		zpxx:"募集中の求人",
		weizhi:"場所",
		xinzi:"給与",
		title6:"あたなにぴったりの仕事があります",
		slon5:"さまざまな職種のクラウドワーカーが登録中。",
		slon6:"仕事の規模、スピード感、予算にマッチする",
		slon7:"優秀な人材を探し、チームを組むことができます。",
		
		zixun:"コンサルティング",
		zixun1:"戦略コンサルティング",
		zixun2:"財務・会計コンサルティング",
		zixun3:"人事・組織コンサルティング",
		zixun4:"業務プロセスコンサルティング",
		zixun5:"物流・SCMコンサルティング",
		zixun6:"マーケティングコンサルティング",
		zixun7:"リサーチ",
		zixun8:"ITコンサルティング",
		
		shangye:"ビジネス",
		shangye1:"経営企画・経営戦略立案",
		shangye2:"新規事業企画・事業開発",
		shangye3:"M&A、合併、提携",
		shangye4:"財務・会計・経理",
		shangye5:"内部監査・内部統制",
		shangye6:"営業",
		
		renshi:"人事",
		renshi1:"採用",
		renshi2:"組織開発・制度設計",
		renshi3:"労務・給与",
		
		it:'IT・開発',
		it1:'Web・アプリケ―ション開発',
		it2:'モバイルアプリ開発',
		it3:'インフラ設計・構築',
		it4:'セキュリティ対策',
		it5:'システム運用保守',
		it6:'ERP導入・CRM導入',
		it7:'データ分析・データ利活用',
		it8:'Webディレクター',
		it9:'PM・PMO',
		
		sheji:'デザイン',
		sheji1:'グラフィックデザイン',
		sheji2:'WebUIデザイン',
		sheji3:'WebUXデザイン',
		sheji4:'プロダクトデザイン',
		sheji5:'パッケージデザイン',
		sheji6:'アートディレクション',
		sheji7:'クリエイティブディレクション',
		sheji8:'空間・店舗デザイン',
		sheji9:'インテリアデザイン',
		
		scyx:"マーケティング",
		scyx1:"ブランディング",
		scyx2:"広告・PR",
		scyx3:"商品企画・開発",
		scyx4:"Web広告運用・SEO",
		scyx5:"デジタルマーケティング",
		
		cyzz:"クリエイティブ制作",
		cyzz1:"動画・映像作成、編集",
		cyzz2:"写真撮影、編集",
		cyzz3:"HP作成",
		cyzz4:"ライティング",
		cyzz5:"コピーライティング",
		cyzz6:"イラスト開発",
		cyzz7:"楽曲制作",
		
		qita:"その他",
		qita1:"翻訳・通訳",
		qita2:"撮影モデル",
		qita3:"データ入力",
		qita4:"各種代行",
		
		title7:"スキル、経歴、強みを可視化する",
		title8:"YOURSダッシュボード",
		slon8:"働く場所や時間、希望する価格帯をはじめ、",
		slon9:"スキルや実績などをダッシュボードで可視化。",
		slon10:"最適な仕事へのマッチングを叶えます。",
		
		title9:"自分を発する、世界とつながる。",
		slon10:"仕事の募集や応募はもちろん、SNSのように",
		slon11:"あなたのパーソナリティを自由に表現しながら",
		slon12:"コミュニケーションすることができます。",
		
		title10:"自分の働き方は、自分が決める。",
		title11:"YOURSは仕事を通して輝き、",
		title12:"仲間と出会い、",
		title13:"人生を楽しみたい人のための",
		title14:"新しい“生息地”です。",
		
		slon13:"自由な生き方、働き方を志向し、人生のハンドルを自ら握る"
	},
	header:{
		ZGZ:"ジョブ探し",
		XZRC:"タレント探し",
		LJ:"つぶやき",
		FBGZ:"ジョブ掲載",
		FBHD:"イベント掲載",
		HD:"イベント",
		QYDL:"法人ユーザーログイン",
		GRDL:"個人ユーザーログイン",
		QYZC:"法人ユーザーとして登録する方はこちら",
		GRZC:"個人ユーザーとして登録する方はこちら",
		WDZY:"マイページ",
		HDZX:"イベント一覧",
		RWGL:"ジョブ管理",
		GZZX:"ジョブ一覧",
		WDGZ:"フォロー中",
		XGMM:"パスワード更新",
		QHBJ:"背景色再設定",
		TCDL:"ログアウト",
		
		YSZC:"プライバシーポリシー",
		SYTK:"利用規約",
		CLGRXX:"個人情報処理",
		DL:"ログイン",
		TDJYFBS:"特定商取引法に基づく表記",
	},
	// 会社情報エディット
	QY_msg:{
		JBXX:"基本情報",
		FGK:"非公開",
		QYMC:"企業名",
		GK:"公開",
		SSHY:"業種",
		QXZ:"選択してください",
		SSXX:"上場情報",
		SSQY:"上場企業",
		FSSQY:"非上場企業",
		QYGM:"会社規模",
		XSGM:"売上規模",
		YGGM:"社員人数",
		ZFFS:"主要取引銀行",
		PJXS:"非表示",
		QYMC:"企業名",
		SSZT:"上場ステータス",
		WBLJ:"外部リンク",
		QYGW:"会社HP",
		LXDH:"電話番号",
		YZBM:"郵便番号",
		DDFX:"都道府県",
		DWDZ:"住所",
		XQDZ:"住所詳細",
		QYLOGO:"会社ロゴ",
		TPSC:"画像アップロード",
		QYJJ:"会社概要",
		ZZXX:"ライセンス他（会社の証明、信頼度向上に繋がるため、ご協力ください）",
		YYZC:"登記簿謄本",
		YYZCMSG:"提出すると提出済みマークが表示されます",
		QTZJ:"他証明書",
		DJSC:"クリックしてアップロード",
		BCXX:"お気に入り",
	},
	// 編集
	my_msg:{
		QZXX:"求職情報",
		RZSJ:"稼働開始可能時期",
		KSSJ:"開催日時",
		XWXZ:"希望時給金額",
		CQSJ:"仕事時間",
		ATJS:"日給",
		ZZJ:"週計算",
		MZ:"毎週",
		XS:"時間",
		AYJS:"月で計算",
		XWGW:"希望職種",
		XZNL:"スキル選択",
		TDTZSX:"ドラッグして順番調整",
		BGFS:"対応方式",
		KCC:"出張可",
		KYC:'リモート可',
		KKQ:"出社可",
		KWP:"出庫可",
		XLDJ:'学科',
		SKRXM:'送金先',
		QSRSKRXM:'送金先の名前を記入してください',
		SKRDZ:'送金先住所',
		QSRSKRDZ:'送金先住所を記入してください',
		SKZH:'送金口座',
		QSRSKZH:'口座番号を記入してください',
		SKYH:'銀行名',
		QSRSKYH:'銀行名を記入してください',
		SKHB:'貨幣',
		QSRHKHBDW:'貨幣単位を記入してください',
		QSRSwiftcode:'Swiftcodeを記入してください',
		ZDMC:'フィード名',
		ZDNR:'フィード内容',
		TJZDYZD:'フィード内容を記入してください',
		XXJL:'学歴',
		TJXXJL:'学歴を追加',
		XXMC:'学校名',
		SXZY:'学部',
		QSRXXMC:'学校名を記入してください',
		QSRZYMC:'ご専攻を記入してください',
		ZXSJ:'在籍期間',
		KSSJ:'開催日時',
		JSSJ:'終了時間',
		ZWXC:'現在の年収',
		CZ:'中学校',
		ZXJL:'特筆事項',
		QSRNSFZDNR:'ご担当の部分を説明してください',
		GZJL:'職務経歴',
		TJGZJL:'職務経歴を追加',
		QSRGSMC:'会社名を記入してください',
		SSZW:'ポジション・タイトル',
		QSRXMGSGSMC:'案件を掲載会社を記入してください',
		ZZSJ:'在職期間',
		GZNR:'職務内容',
		GZCJ:'実績・表彰歴',
		QSRNSFZDBFDCG:'ご自身の担当内容と成果を記入してください',
		XMJL:'過去に携わったプロジェクト',
		XMMC:'プロジェクト名',
		QSRXMMC:'プロジェクト名を記入してください',
		XMSS:'案件所属',
		XMSJ:'期間',
		KSSJ:'開催日時',
		FZSX:'概要',
		XMCG:'実績・成果',
		BCXX:'お気に入り',
		JBXX:'基本情報',
		ZCXX:'登録情報',
		CSRQ:'生年月日',
		NL:'年齢',
		S:'歳',
		YWNL:'スキルセット',
		XZNL:'スキル選択',
		ZYBS:'スキル信用度',
		TZTZSX:'ドラグしながら順番調整可能',
		LALBB:'例：＊＊株式会社人事部部長',
		XB:'性別',
		MAN:'男性',
		WOMAN:'女性',
		BXHD:'回答したくありません',
		XBGKXZ:'性別を公開',
		NC:'ニックネーム',
		GK:'公開',
		FGK:'非公開',
		ZSXM:'本名',
		ENXM:'英語名',
		GKZSXM:'本名を公開',
		LXFS:'代表電話',
		YZBM:'郵便番号',
		DDFX:'都道府県',
		DWDZ:'住所',
		XXDD:'詳細住所',
		BCXX:'補足情報',
		TX:'プロフィール画像',
		ZPJ:'ポートフォリオ画像',
		TPSC:'画像アップロード',
		WBLJ:'外部リンク',
		GRJJ:'自己紹介',
		QR:'確認',
	},
	// つぶやき
	home:{
		DTGC:'おすすめ',
		GZDT:'フォロー中',
		WDDT:'つぶやき',
		TJYH:'推薦ユーザー',
		FDXX:'メッセージを送信',
		FH:'戻る',
		YGZ:'フォロー中',
		WGZ:'フォローしていない',
		PL:'評価',
		FBNDPL:'コメント掲載',
		CKQBPL:'コメント閲覧',
		XX:'メッセージ',
		HF:'返信',
		SS:'検索',
	},
	// イベント掲載
	add_activity:{
		HDZX:'イベント一覧',
		SSHD:'イベント検索',
		YNCJ:'ご作成',
		GXQD:'興味枠',
		CYD:'参加予定',
		WQHD:'参加済みイベント',
		FBHD:'イベント掲載',
		HDMC:'イベント名',
		HDSJ:'開催日時',
		SQ:'タイムゾーン',
		Z:'ないし',
		HDDZ:'イベント場所',
		XS:'オンライン',
		XX:'オフライン',
		DTXD:'地図から選択',
		HDRS:'制限人数',
		HDDD:'参加資格',
		WXZ:'制限なし',
		XSH:'審査あり',
		LBTP:'サムネイル',
		TJBL:'推奨割合',
		ZSTP:'メイン画像',
		GRJJ:'自己紹介',
		HDXQ:'イベント詳細',
		QX:'いいえ',
		HY:'会議',
		QSRAlisHYID:'Alis会議のIDを記入してください',
		QSRAlisHYMM:'Alis会議のパスワードを記入してください',
		QAlisSCHY:'Alis会議を作成へ',
		QSRXSHDLJ:'オンラインイベントのリンクを記入してください',
		QR:'確認',
	},
	job:{
		ZZZX:'ジョブ一覧',
		QHDWBGZGL:'応募一覧へ切り替え',
		SSRW:'案件検索',
		DKS:'審査中/募集中',
		JXZ:'依頼中',
		YWC:'納品済',
		FBGZ:'ジョブの新規作成',
		ZYXZ:'職種選択',
		ZDKXZZZ:'最多5種類選択可能',
		SJS:'デザイナー',
		ZJ:'作家',
		JZ:'ジャーナリスト',
		WNZZ:'web制作',
		JR:'金融',
		GZMC:'上部名称',
		SXYWNL:'スキルセット',
		GZGS:'ジョブ所属',
		ZZWT:'直接委託',
		SFWT:'第三者委託',
		QSRWTDSFDMC:'第三者委託先の名前を記入してください',
		JCFS:'報酬パターン',
		AGZSC:'時間給',
		GDYJ:'固定報酬',
		ACJF:'回数で計算',
		YJFW:'フィー幅',
		Z:'乃至',
		D:'低い',
		G:'高い',
		GZSJ:'案件期間',
		GZLGD:'案件工作量固定',
		GZSJGD:'時間固定式',
		CQGZ:'長期ジョブ',
		CQSJ:'稼働時間/勤務時間',
		ATJS:'日数で計算',
		AZJS:'週数で計算',
		AYJS:'月数で計算',
		GZDD:'勤務地',
		HW:'海外',
		GZFS:'働き方',
		YC:'リモート',
		XX:'オフライン',
		BX:'制限なし',
		SFCC:'出張有無',
		XYCC:'出張あり',
		WXCC:'出張なし',
		XQRS:'募集人数',
		SFJP:'その他',
		PTZM:'普通応募',
		JP:'急募',
		GZXX:'働き方',
		FY:'副業',
		JZRQ:'申込期限',
		XGWJ:'関連ファイル',
		CYKXZ:'申込者ダウンロード可',
		SYRKXZ:'だれでもダウンロード可',
		YoursYXRP:'YoursデザインRP',
		DJSC:'クリックしてアップロード',
		GZJJ:'案件概要',
		GZSM:'案件詳細',
	},
	login:{
		QYZC:'企業登録',
		GRZC:'個人登録',
		LJDL:'ログイン',
		BCDLZT:'ログイン状態を維持する',
		WJDLMM:'パスワードをお忘れの方',
		QTDLFS:'または',
		QTDLFS2:'以下アカウントでログイン',
		CJXZH:'新規アカウント作成',
		QSRNZCSDDZYX:'登録時点記入したE-mailか電話を再度入力してください',
		QSRNDMM:'Yoursのログインパスワードを入力してください',
		
	},
	work:{
		QZRQ:'最新更新時間',
		BHWC:'却下する',
		QDGZWC:'委託先から納品通知が届きました、3営業日以内にご対応ください。',
		WCGZ:'納品確認',
		JFWJXZ:'納品ファイルをダウンロード',
		HTQD:'作業開始',
		JFGZ:'納品',
		GZJS:'お支払い',
		GZDP:'評価',
		ZKCKGD:'クリックしてもっと進捗確認できます',
		JN:'スキル',
		ZL:'クオリティ',
		GT:'コミュニケーション',
		GX:'パートナーシップ',
		JD:'スケジュール',
		SJYJ:'実質コミション',
		QSRPJNR:'コメント内容を入力してください',
		QSRSJJFYJ:'業務管理のため、実際払った金額を入力してください',
		JDPF:'評価',
		FS:'メッセージを送信',
		GZXZ:'納品する',
		LSJF:'納品履歴',
		SCDGZ:'お気に入り',
		JXZDGZ:'作業中',
		SQZDGZ:'応募中',
		WQGZ:'納品完了済み',
		QD:'確認',
		QQDYWC:'関連作業できたかどうかをご確認ください',
		SQZ:'応募中',
		YJJ:'不合格',
		NDPJ:'評価',
		WJSJ:'完了時間',
		WBGZ:'ジョブ一覧',
		JHDZCJ:'自分のジョブ管理に借り換え',
		QSRHZGCZ:'案件に関する感想を述べてみてください',
		PJNYGZHZ:'依頼主の評価を書きこんでください',
		QXSC:'お気に入り取消し',
	},
	job_detail:{
		WTR:'依頼人',
		FR:'企業',
		GR:'個人',
		TC:'必須スキル',
		SCYX:'販促',
		SJZZXG:'デザイン関係',
		QT:'その他',
		ZX:'コンサルティング',
		RS:'人事',
		WTFS:'委託形式',
		XM:'案件',
		GDBCZ:'固定報酬制',
		KSL:'相談可',
		SJDJZ:'時間給単価',
		JYRW:'案件に基づき',
		BC:'報酬',
		WZD:'指定しない',
		YX:'以下',
		QTSX:'その他詳細',
		KYC:'リモート可',
		JP:'急募',
		FSZM:'複数人募集',
		KJZ:'副業／兼業可',
		JCXX:'基本情報',
		GZPF:'依頼主評価',
		BMQK:'エントリー ',
		BMRS:'申込状況',
		BMRS:'申込人数',
		GDYJ:'固定コ依頼費',
		SCRS:'お気に入り人数',
		QDRS:'決定人数',
		SQGZ:'申込／アプライ',
		DDXL:'応募待ち',
		TXSJZ:'*日*時間締め切り',
		BMJZ:'申込締め切り',
		ZMYQ:'求人詳細',
		GZXQ:'案件詳細',
		DJXZ:'クリックしてダウンロード',
		XBLC:'選抜流れ',
		GZXD:'選考',
		QDHT:'作業開始',
		KSGZ:'作業開始',
		JFGZ:'納品',
		SQWC:'納品報告／案件完了',
		JYWC:'取引完了',
		RWJD:'案件進捗',
		JFSM:'納品説明',
		SYYMTJWC:'ご依頼完了しましたので、決済を進ませてください',
		JFSJ:'納品時間',
		WJSC:'ファイルアップロード',
		JFSM:'納品説明',
		ZSJGHXYM:'こちらはメインページのデザインとなります、ティスト的にどうだとご確認ください。',
	},
	activity:{
		HDLB:'イベントリスト',
		FXHD:'イベントを見つける',
		HDXS:'形式',
		HDZT:'ジャンル',
		QB:'全部',
		PXFS:'並び順',
		ZNPX:'おすすめ',
		CJ:'申込',
		HDXX:'イベント予告',
		HDGY:'イベント概要',
		FQZ:'オーガナイザー／企画者',
		FQSJ:'イベント掲載日',
		HDSJ:'開催日時',
		WYCJ:'申込',
		JHDD:'イベント場所',
		
		HDRS:'制限人数',
		CJXZ:'参加資格',
		HDJJ:'イベント概要',
		BJHD:'イベント編集',
		BJ:'エディット',
		SC:'削除',
		CYZ:'参加者',
		GXQD:'お気に入り',
		DSH:'審査待ち',
		YSH:'合格',
		QQDSC:'削除していいですか',
		QF:'一斉送信',
		QFXX:'一斉送信内容',
		QSRQFDXXNR:'一斉送信の内容を記入してください',
		QFXXGCYZ:'申込者に一斉メッセージ送信',
		GLHD:'イベント管理',
		YJS:'完了',
		GXQ:'お気に入り',
		YBM:'申込済',
		YCY:'参加した',
		NQDYQXBMHD:'このイベントをキャンセルしていいですか？',
		YNCJ:'掲載済み',
		WCYD:'参加した',
		YCHD:'イベント削除',
		NQDYSCBMHD:'イベントを削除していいですか？',
		NQDYYCHD:'イベントを削除していいですか？',
		TP:'画像',
		SP:'ビデオ',
		HD:'イベント',
		CKQBPL:'コメント全部閲覧',
		YSMXFXGDJD:'あなたの気分や考え、気になる話題をシェアしてみよう！',
	},
	QY:{
		YRZ:'認証済',
		HYGS:'業界',
		RYGM:'従業員数',
		PFCS:'評価件数',
		GYPF:'企業評価',
		QYGW:'企業HP',
		ZT:'ステータス',
		QYJJ:'企業概要',
		AJPJ:'案件コメント',
		QBDT:'つぶやき一覧',
		WZSPJ:'未公開評価',
		YZSPJ:'公開評価',
		XPLMRSFZS:'新コメントを公開かどうか',
		YC:'隠し',
		ZS:'公開',
		JP:'急募',
		GDXZ:'報酬固定',
		WCSJ:'完了時間',
		PF:'評価',
		FBRWS:'案件掲載数',
		PJXC:'平均報酬',
		MFZC:'無料登録',
		YYZH:'既にアカウントをお持ちの方はこちら',
		// ZCCWHY:'ユーザー登録は プライバシーポリシー と利用規約を承認したと見なします。',
		ZCCWHY:'ユーザーは当サイトへ登録する事により、',
		ZCCWHY2:'すべての記載内容について同意したとみなします。',
		XYB:'次へ',
		FH:'戻る',
		CXFS:'再送',
		FHCXFS:'再度認証コードを送る',
	},
	task:{
		GYCK:'応募者チェック',
		JDCK:'進捗確認',
		GZPJ:'ジョブコメント',
		GZXX:'案件詳細',
		GZMC:'案件名',
		FQSJ:'掲載時間',
		XQRS:'応募人数',
		GZYJ:'依頼費',
		ZSYQ:'証明書リクエスト',
		ZNYQ:'スキルセット',
		BJGZ:'ジョブ編集',
		BJ:'エディット',
		GZJJ:'案件概要',
		SQZ:'応募',
		MTZ:'面談中',
		YJJ:'不合格',
		YTG:'合格',
		DDMS:'面談待ち',
		GGZZZJXZ:'このジョブは進行中の関係で、削除できません',
		SQJSGZ:'決済要望',
		WCJSGZ:'決済完了',
		SQJS:'支払い承認',
		WCJS:'支払い完了',
		ZDPF:'評価を自動に',
		TDFS:'フォロー',
		TDGZ:'フォロー',
		ZKCKGDRW:'クリックしてジョブをもっと閲覧できる',
		ZWGD:'まだデータなしです',
		ZWGDFS:'以上',
		ZWGDGZ:'以上',
		QQDQXGZ:'フォロー取消ししていいですか',
		XGZDFH:'相手をフォローしてから、メッセージ送信できるようになります',
		SF:'三方',
		XMGL:'案件管理',
		LT:'チャット',
		JNPG:'スキル評価',
		SJPG:'実績評価',
		DDPG:'受注評価',
		CGDDPG:'発注評価',
		CPZH:'ポートフォリオ',
		TJXXXX:'おすすめ詳細',
		TJ:'おすすめ',
		XQXX:'詳細',
		JB:'受注',
		FB:'発注',
		GX:'更新',
		XXXQ:'学校紹介',
		XXMC:'学校名',
		GSMC:'企業名',
		GWMC:'肩書',
		GWXC:'給料',
		RZSJ:'在職期間',
		GZNR:'現在の職務内容',
		ZYMC:'ご専攻',
		XMSS:'プロジェクト所属',
		XMSJ:'プロジェクト継続期間',
		FZSX:'ご担当内容',
		DZYXDZ:'E-mail',
		DZJZMSZ:'英数字と符号8～16桁',
		MM:'パスワード',
		QDMM:'パスワード確認',
		LN:'年齢',
		RJYZ:'ロボットではない検証',
		QDZC:'登録する',
		WTYSYTK:'プライバシーポリシーと利用規約に同意します',
		WDGZ:'マイお気に入り',
		WDFS:'フォロワー',
		TJGZ:'おすすめジョブ',
		FGK:'非公開',
		YZBM:'郵便番号',
		TJHD:'おすすめイベント',
		DWDZ:'住所',
		CSRQ:'生年月日',
		QXZCSRQ:'生年月日を選択してください',
		ZYBS:'職業信用度',
		BMCJ:'申込',
		GZLM:'案件ジャンル',
		RWL:'ジョブ量',
		SYXW:'残存応募者数',
		JJDZ:'集合場所',
		HYH:'更新',
		HG:'フォロー',
		YHG:'フォロー済',
		QBFS:'フォロワー',
		SSYH:'ユーザー検索',
		QDBZGZCR:'この方へのフォローを取消ししていいですか？',
		JDJTGYXJXFK:'進捗情報をご指定のE-mailまで連絡させていただきますので、再度E-mailをご確認ください。',
		XGYX:'E-mail設定し直す',
		XG:'変更',
		DZ:'いいね',
		PL:'レコメンド',
		PL3:'コメントする',
		PL2:'コメント',
		FX:'シェア',
		HF:'返信',
		RQ:'日付',
		PSY:'コメント者',
		PF:'採点',
		XMLX:'案件タイプ',
	},
	other:{
		GX:'更新',
		KYC:'リモート可',
		FSMC:'複数人募集',
		KJZ:'副業／兼業可',
		WTFS:'委託形式',
		TC:'クオリティ',
		SCYX:'販促',
		SJ:'デザイン',
		ZZXG:'制作関係',
		QT:'その他',
		ZX:'コンサルティング',
		RS:'人事',
		SY:'ビジネス',
		FR:'法人',
		ZJAJ:'仲介案件',
		RM:'人気',
		ZX:'最新',
		YJGD:'金額順',
		G:'高い',
		D:'低い',
		GJZSS:'キーワード検索',
		JBMSJ:'申込開始まで',
		JRWKSSJ:'上部開始まで',
		BC:'お気に入り',
		XYRS:'募集人数',
		CZMM:'パスワード更新',
		FHDL:'ログイン画面に戻る',
		FSYZM:'更新に必要な認証コードが送信されました',
		QSRNZCDDDYJDZ:'パスワード変更に際して下記をご入力下さい',
		DZYX:'E-mail',
		SJHM:'携帯番号',
		FSCG:'送信できました',
		
		QDDZYJ:'Emailアドレスに送信した4桁の承認コードを確認ください。',
		QDDZYJ4:'記載されている４桁の認証コードをご入力ください',
		QDDZYJ3:'※承認コードが届かない場合は、以下「再送」を押し、再度受け取りをしてください。',
		QDDZYJ2:'登録した携帯電話番号にSMS認証コードが送信されたことを確認する',
		QSRGGHDMM:'更新パスワードを入力してください',
		QCZSRMMYJXQD:'更新パスワードを再度入力してください',
		GXDLXX:'ログイン情報を更新',
		PFPX:'評価順',
		DLSJPX:'ログイン順',
		XCPX:'希望報酬順',
		ZCSJ:'登録新着順',
		CJSJ:'作成時間',
		SQRS:'募集人数',
		CKXQ:'案件詳細'
		
	}
}